import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import TurnLeftOutlinedIcon from '@mui/icons-material/TurnLeftOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import clsx from 'clsx';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { LOADING_TEXT } from 'src/constants';
import { formatDate } from 'src/helpers';
import { useDeleteSessionMutation } from 'src/redux/services/clients';
import { TScope, useRevokeScopesMutation } from 'src/redux/services/users';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { IConfirmationModalProps } from '../modal/ConfirmationModal';
import { Card, ICardProps } from '../shared/Card';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { MenuControls } from '../shared/MenuControls';
import styles from './ScopeCard.module.css';
import { getScopeProps } from './utils';

export interface ICardScopeProps extends ICardProps {
  userId: string;
  items: TScope[];
  index: number;
  setModalProps: Dispatch<SetStateAction<IConfirmationModalProps>>;
  updateItems: (items: TScope[], totalCount: number) => void;
}

const CardScope: FC<ICardScopeProps> = (props) => {
  const { userId, items, index, setModalProps, updateItems } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [revokeScopes] = useRevokeScopesMutation();
  const [deleteSession] = useDeleteSessionMutation();
  const { client, scopes, created_at } = items[index] || {};
  const id = client?.client_id;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseModal = () => setModalProps((prev) => {
    return {...prev , isOpen: false}
  })

  const handleDeleteScopes = async () => {
    try {
      await revokeScopes({ userId, clientId: id }).unwrap();
      items.splice(index, 1);
      updateItems(items, items.length);
    } catch (error) {
      console.error('rejected', error);
    }
  };

  const handleDeleteSessions = async () => {
    try {
      await deleteSession({ userId, clientId: id }).unwrap();
    } catch (error) {
      console.error('rejected', error);
    }
  };

  const modalPrams = [
    {
      onAction: () => {
        handleDeleteSessions();
        onCloseModal();
      },
      title: 'Удалить сессии',
      actionButtonText: 'Удалить',
      mainMessage: [
        `Все ваши сессии и токены для приложения ${client?.name} будут удалены`]
    },
    {
      onAction: () => {
        handleDeleteScopes();
        onCloseModal();
      },
      title: 'Отозвать разрешения',
      actionButtonText: 'Отозвать',
      mainMessage: [
        `Приложение ${client?.name} больше не сможет получать доступ к вашим данным`
      ]
    }
  ];

  const scopeControls = [
    {
      icon: LogoutOutlinedIcon,
      title: 'Завершить сеансы',
      action: async () => {
        handleClose();
        setModalProps({isOpen: true, onClose: onCloseModal, ...modalPrams[0]});
      },
      addDivider: true,
    },
    {
      icon: TurnLeftOutlinedIcon,
      title: 'Отозвать разрешения',
      action: () => {
        handleClose();
        setModalProps({isOpen: true, onClose: onCloseModal, ...modalPrams[1]});
      },
    },
  ];

  return (
    <Card
      {...props}
      cardId={id}
      avatarUrl={client?.avatar}
      isImage
      content={
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.clientInfo}>
              <Box className={styles.clientMainInfo}>
                <Link className={styles.clientTitle} href={client?.domain}>
                  {client?.name || LOADING_TEXT}
                </Link>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {formatDate(created_at) || LOADING_TEXT}
                </CustomTypography>
              </Box>
              <div className={styles.clientAddInfo}>
                {client?.description && (
                  <div className={styles.clientDescription}>
                    <CustomTypography color="grey" className={clsx('text-12')}>
                      Описание:
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')}>
                      {client?.description || LOADING_TEXT}
                    </CustomTypography>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.clientScopesInfo}>
              <CustomTypography color="grey" className={clsx('text-12')}>
                Выданные разрешения:
              </CustomTypography>
              <Box className={styles.scopesList}>
                {scopes?.map((scope, index) => {
                  const scopeProps = getScopeProps(scope);
                  return (
                    <IconWithTooltip
                      key={index}
                      muiIcon={scopeProps.icon}
                      muiIconStyles={styles.scopeIcon}
                      customTitle={scopeProps.description}
                      customStyleButton={styles.scopeButton}
                    />
                  );
                })}
              </Box>
            </div>
          </div>
          <div className={styles.moreButtonWrapper}>
            <Button aria-describedby={id} onClick={handleClick} className={styles.moreButton}>
              <CustomMuiIcon Icon={MoreHorizOutlinedIcon} color='grey' />
            </Button>
            <MenuControls
              anchorEl={anchorEl}
              handleClosePopover={handleClose}
              controls={scopeControls}
            />
          </div>
        </div>
      }
    />
  );
};

export default CardScope;
