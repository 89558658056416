import { useEffect, useRef, useState } from "react";
import { getScreenSize } from "src/helpers";

export function useResizeObserver() {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        try {
          for (const entry of entries) {
            setWidth(getScreenSize(entry.contentRect.width));
          }
        } catch (error) {
          console.error('ResizeObserver error:', error);
        }
      });
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return { ref, width };
}