import TextField from '@mui/material/TextField';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditEmailTemplates.module.css';
import { IEmailTemplate } from './EmailTemplate';
import { useEditSettingsMutation } from 'src/redux/services/settings';
import { BaseSidePanel } from '../../sidePanel/BaseSidePanel';
import { ViewEmailTemplate } from './ViewEmailTemplate';

type TEditEmailTemplate = {
  isOpen: boolean;
  close: () => void;
  template?: IEmailTemplate;
};

export const EditEmailTemplate: FC<TEditEmailTemplate> = ({ isOpen, close, template }) => {
  const [content, setContent] = useState(template?.content || '');
  const [editSettings] = useEditSettingsMutation();

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSave = async () => {
    if (template) {
      try {
        await editSettings({
          email_templates: [{ action: template.action, content, title: template.title }],
        }).unwrap();
        close();
      } catch (error) {
        console.error('Failed to save the template:', error);
      }
    }
  };

  useEffect(() => {
    setContent(template?.content || '');
  }, [template]);

  return (
    <>
      <BaseSidePanel
        handleClosePanel={close}
        isOpenPanel={isOpen}
        title={`Редактировать шаблон письма
              ${template ? `'${template.title.toLowerCase()}'` : ''}`}
        description={`Измените шаблон письма для события ${template ? template.action : ''}`}
        isNoBackdrop
        handleConfirm={handleSave}
      >
        <div className={styles.wrapper}>
          <div>
            <ViewEmailTemplate content={content} />
          </div>
          <div>
            <TextField
              className="text-12"
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              value={content}
              onChange={handleContentChange}
            />
          </div>
        </div>
      </BaseSidePanel>
    </>
  );
};
