import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { providerParamsToFormData } from '../../helpers';
import { getAccessToken } from '../../service/auth';
import { TProviderColors } from '../../components/Providers/edit/ProviderColor';
import { EditProviderParams } from 'src/components/Providers/edit/EditProvider';

export type TShortProvider = {
  id: number;
  type: string;
  name: string;
  avatar: string;
};

export type TOauthProvider = {
  id: string;
  name: string;
  type: ProviderType;
  description?: string;
  avatar: string | null;
  path_to_avatar?: string;
  is_active: boolean;
  is_public: boolean;
  scopes?: string;
  client_id: string;
  issuer: string;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  token_endpoint: string;
  external_client_id: string;
  external_client_secret: string;
  authorization_endpoint: string;
  redirect_uri: string;
  userinfo_endpoint: string;
  mapping?: string;
  params: EditProviderParams;
};

export type TLdapParams = {
  url: string;
  base: string;
  domain: string;
  mapping: string;
  search_filter: string;
  admin_login: string;
  admin_password: string;
};

export type T1CParams = {
  url: string;
  admin_login: string;
  admin_password: string;
  mapping: string;
};

export type TIdmParams = {
  url: string;
  groups: string;
  mapping: string;
};

export type TEsiaParams = {
  scopes: string;
  external_client_id: string;
  issuer: string;
  certificate: string;
  sign_endpoint: string;
  verify_endpoint: string;
};

export type TKloudParams = {
  external_client_id: string;
  external_client_secret: string;
  issuer: string;
};

export type TEmailParams = {
  root_mail: string;
  mail_hostname: string;
  mail_port: string;
  mail_password: string;
  mail_code_ttl_sec: string;
};

export type TMiscProvider = {
  id: string;
  name: string;
  client_id: string;
  type: string;
  description?: string;
  avatar: string | null;
  path_to_avatar?: string;
  is_active: boolean;
  is_public: boolean;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  params?: TLdapParams | T1CParams | TIdmParams | TKloudParams | TEmailParams | TEsiaParams;
  provider_colors?: TProviderColors;
  provider_title?: string;
  show_provider_avatar?: boolean;
  disable_password_reset?: boolean;
};

export enum EGetProviderAction {
  add = 'add',
  auth = 'auth',
  change = 'change',
  all = 'all',
}

export enum ProviderType {
  GOOGLE = 'GOOGLE',
  YANDEX = 'YANDEX',
  VK = 'VK',
  MAILRU = 'MAILRU',
  CUSTOM = 'CUSTOM',
  CREDENTIALS = 'CREDENTIALS',
  EMAIL = 'EMAIL',
  ETHEREUM = 'ETHEREUM',
  SMS = 'SMS',
  KLOUD = 'KLOUD',
  OAUTH = 'Oauth2',
  PHONE = 'PHONE',
}

export type TEditProvider<T = TMiscProvider> = {
  isOpen: boolean;
  onClose: () => void;
  providerToEdit: Partial<T & { provider_id: string }> | null;
};

export type TCreateProvider = {
  isOpen: boolean;
  onClose: (createChooseProvider?: boolean) => void;
  pathToAvatar: string;
};

export const providerApi = createApi({
  reducerPath: 'providerApi',
  tagTypes: ['Providers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/clients`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProviders: builder.query<
      (TOauthProvider | TMiscProvider)[],
      { client_id: string; onlyActive?: boolean; action: EGetProviderAction; types?: string[] }
    >({
      query: ({ client_id, onlyActive, action, types }) =>
        `${client_id}/providers?only_active=${!!onlyActive}&action=${action}${
          types ? `&types=${types.join(',')}` : ''
        }`,
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: 'Providers' as const,
                id,
              })),
              { type: 'Providers', id: 'LIST' },
            ]
          : [{ type: 'Providers', id: 'LIST' }];
      },
    }),

    createProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider | TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `${client_id}/providers`,
        method: 'POST',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    updateProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider | TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
            redirect_uri: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `${client_id}/providers/${body.provider_id}`,
        method: 'PUT',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
      },
    }),

    activateProviders: builder.mutation({
      query: ({ body, client_id }: { body: { providers: number[] }; client_id: string }) => ({
        url: `${client_id}/providers/activate`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deactivateProviders: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: {
          providers: number[];
        };
        client_id: string;
      }) => ({
        url: `${client_id}/providers/deactivate`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deleteProvider: builder.mutation<
      undefined,
      {
        client_id: string;
        provider_id: string;
      }
    >({
      query: (body) => {
        return {
          url: `${body.client_id}/providers/${body.provider_id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useCreateProviderMutation,
  useActivateProvidersMutation,
  useDeactivateProvidersMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
} = providerApi;
