import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './EditProviders.module.css';
import { filterMethods } from 'src/enums';
import { TMiscProvider, TOauthProvider } from 'src/redux/services/provider';
import { CLIENT_ID } from 'src/constants';

interface IFilterProps {
  anchorPopover: React.RefObject<HTMLButtonElement>;
  clientId: string;
  updateProviders: (value: (TMiscProvider | TOauthProvider)[]) => void;
  items?: (TMiscProvider | TOauthProvider)[];
}

const FilterComponent: FC<IFilterProps> = ({ 
  anchorPopover, 
  items,
  clientId,
  updateProviders
}) => {
  const [selectedFilter, setSelectedFilter] = useState<filterMethods>(filterMethods.all);
  const [isOpen, setIsOpen] = useState(false);
  const filter = [filterMethods.all, filterMethods.public, filterMethods.private];

  useEffect(() => {
    filteredItems(selectedFilter, clientId, items);
  }, [selectedFilter]);

  const filteredItems = (
    selectedFilter: filterMethods,
    clientId: string,
    items?: (TMiscProvider | TOauthProvider)[],
  ) => {
    const filtered = items?.slice()
    ?.filter((p) => {
      switch (selectedFilter) {
        case 'Публичные':
          return p?.is_public;
        case 'Для приложения':
          return p?.client_id === clientId;
        default:
          return true;
      }
    })
    .sort((a, b) => {
      if (a.is_active !== b.is_active) return !a.is_active ? 1 : -1;
      return a.client_id === CLIENT_ID ? 1 : -1;
    });
    updateProviders(filtered || []);
  };

  return (
    <div className={styles.filter}>
      <Button
        ref={anchorPopover}
        onClick={() => setIsOpen(true)}
        endIcon={<KeyboardArrowDownOutlinedIcon fill="#B6BAC6" />}
      >
        <CustomTypography className={clsx('text-14')} color="grey">
          Тип:
        </CustomTypography>
        <CustomTypography className={clsx('text-14', styles['filter-text'])}>
          {selectedFilter}
        </CustomTypography>
      </Button>
      <Popover
        classes={{ paper: styles.popover }}
        open={isOpen}
        anchorEl={anchorPopover.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {filter.map((item) => (
          <CustomPopoverButton
            key={item}
            onClick={() => {
              setSelectedFilter(item);
              setIsOpen(false);
            }}
            startIcon={
              <CheckOutlinedIcon
                className={clsx(styles['popover-button-icon'], {
                  [styles['hidden-button-icon']]: item !== selectedFilter,
                })}
              />
            }
          >
            {item}
          </CustomPopoverButton>
        ))}
      </Popover>
    </div>
  );
};

export default FilterComponent;
