import React, { useState } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom-v5-compat';
import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { isAdministrator, showDisplay } from '../../helpers';
import { Roles } from '../../enums';
import { TopTabsApplication } from '../applications/old/TopTabsApplication';

type AdminRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  userRole?: Roles;
  isUserProfileLoading: boolean;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  userRole: state.user.userProfile.role,
  isUserProfileLoading: state.user.isUserProfileLoading,
});

const AdminRouteComponent: React.FC<AdminRouteProps> = ({
  userId,
  passwordChangeRequired,
  userRole,
  isUserProfileLoading,
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' && userId && passwordChangeRequired;
  // (passwordChangeRequired || !email || !birthdate);

  //TODO а точно ли тут это надо?
  useEffect(() => {
    let cleanupFunction = false;

    const getToken = async () => {
      try {
        const token = await getAccessToken();

        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
          !cleanupFunction && !checkResult && setWait(false);
        } else {
          !cleanupFunction && setIsAuthorized(false);
          !cleanupFunction && setWait(false);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }
    };
    getToken();

    if (isAdministrator(userRole)) {
      !cleanupFunction && setIsAdmin(true);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [isUserProfileLoading]);

  useEffect(() => {
    if (isAuthorized && isAdmin) {
      setWait(false);
      showDisplay();
    }
  }, [isAuthorized, isAdmin]);

  const AdminOutlet = () => {
    if (isAuthorized && isAdmin) {
      if (redirectToFillProfile) return <Navigate to="/fill-profile" replace />;

      return (
        <>
          <TopTabsApplication />
          <Outlet />
        </>
      );
    }
    return <Navigate to={'/profile'} replace />;
  };

  return wait ? <LinearProgress /> : <AdminOutlet />;
};

export const AdminRoute = connect(mapStateToProps)(AdminRouteComponent);
