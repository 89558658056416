import { useTheme } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { formatDate } from 'src/helpers';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { IconWithTooltip } from '../../shared/IconWithTooltip';
import styles from './LicenseItem.module.css';
import { deleteLicense } from './requests';
import { useDispatch } from 'react-redux';
import { setNotice } from 'src/redux/noticesSlice';

interface ILicenseItem {
  licenseName: string;
  licenseId: string;
  expirationTime: number;
  licenseKeys: string[];
  connectedProvidersList: { id: string; name: string }[];
  updateLicenses: () => void;
}

const LicenseItem = ({
  licenseName,
  licenseId,
  expirationTime,
  licenseKeys,
  connectedProvidersList,
  updateLicenses,
}: ILicenseItem) => {
  const [isDeleteLicenseModalOpen, setIsDeleteLicenseModalOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleDeleteLicense = async () => {
    setIsDeleteLicenseModalOpen(false);
    try {
      const isDeleted = await deleteLicense(licenseId);
      if (isDeleted) {
        updateLicenses();
      }
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Ошибка удаления лицензии: ${error}`,
          timestamp: new Date().toString(),
          avatar: '',
        }),
      );
    }
  };

  const isActive = !!connectedProvidersList.length;
  //проверка на просроченность лицензии с текущей датой в миллисекундах
  const isExpired = expirationTime && new Date(expirationTime * 1000) < new Date();

  return (
    <div className={styles['item-wrapper']}>
      <div className={styles['item-info-container']}>
        <div className={styles['item-name-container']}>
          <CustomTypography className={clsx('text-14', styles['item-name'])}>
            {licenseName}
          </CustomTypography>
          <div className={styles['chips-container']}>
            <div
              className={clsx(
                styles['chip-wrapper'],
                isActive ? styles['active-bg'] : styles['not-active-bg'],
              )}
            >
              <CustomTypography
                className={clsx('text-12')}
                sx={{
                  color: isExpired
                    ? theme.palette.error.main
                    : isActive
                    ? theme.palette.custom.onAccentColor
                    : theme.palette.custom.mainGrey,
                }}
              >
                {isExpired ? 'Просрочена' : isActive ? 'Активна' : 'Неактивна'}
              </CustomTypography>
            </div>
            {connectedProvidersList?.map((provider) => (
              <div
                key={provider.id}
                className={clsx(styles['chip-wrapper'], styles['not-active-bg'])}
              >
                <CustomTypography
                  className={clsx('text-12')}
                  sx={{
                    color: theme.palette.custom.mainGrey,
                  }}
                >
                  {provider.name}
                </CustomTypography>
              </div>
            ))}
          </div>
        </div>
        <CustomTypography className={clsx('text-12')} color="grey">
          Срок действия:{' '}
          {expirationTime === 0 ? 'бессрочная' : formatDate(new Date(expirationTime * 1000))}
        </CustomTypography>
        <CustomTypography className={clsx('text-12')} color="grey">
          Ключи: {licenseKeys.join(', ')}
        </CustomTypography>
      </div>
      <IconWithTooltip
        dataAttribute={'delete-license-button'}
        iconType="delete"
        onClick={() => setIsDeleteLicenseModalOpen(true)}
      />

      {isDeleteLicenseModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteLicenseModalOpen}
          onAction={handleDeleteLicense}
          onClose={() => setIsDeleteLicenseModalOpen(false)}
          title={'Удалить лицензию'}
          mainMessage={[
            `Провайдеры, к которым привязана лицензия ${licenseName}, перестанут работать`,
          ]}
        />
      )}
    </div>
  );
};

export default LicenseItem;
