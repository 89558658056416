import { BACKEND_URL } from "src/constants";
import { getAccessToken } from "src/service/auth";

export type TSentry = {
  dsn: string | null;
  user_id: string | null;
  enabled: boolean;
}

/**
 * Запрашивает текущие настройки Sentry c сервера.
 * @returns Объект с настройками Sentry.
 */
export const getSentry = async (): Promise<TSentry> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(`${BACKEND_URL}/api/v1/sentry`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Sentry');
    }

    const data: TSentry = await response.json();
    return data;
  } catch (error) {
    console.log('getSentry error: ', error);
    throw error;
  }
};

/**
 * Обновляет настройки Sentry на сервере.
 * @param data - Обновлённые настройки Sentry.
 */
export const updateSentry = async (data: TSentry): Promise<void> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(`${BACKEND_URL}/api/v1/sentry`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to update Sentry');
    }
  } catch (error) {
    console.log('updateSentry error: ', error);
    throw error;
  }
};