import React, { useState, FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import styles from './ProviderProfile.module.css';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import ListItem from '@mui/material/ListItem';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { getImageURL, randomString } from '../../helpers';
import {
  EGetProviderAction,
  ProviderType,
  TOauthProvider,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import { getAccessToken } from '../../service/auth';
import { setNotice } from '../../redux/noticesSlice';
import Cookies from 'universal-cookie';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { useBindEthereumAccountMutation, userApi } from '../../redux/services/user';
import { useLazyGetNonceQuery } from '../../redux/services/ethereum';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useGetOauthMutation } from '../../redux/services/auth';
import { CustomTypography } from '../custom/CustomTypography';
import { Button } from '@mui/material';
import { ConfirmationModal } from '../modal/ConfirmationModal';

const mapStateToProps = (state: RootState) => ({
  userAvatar: state.user.userProfile.picture,
  userId: state.user.userProfile.id,
  userNickname: state.user.userProfile.nickname,
});

type TProviderProfileComponent = {
  userAvatar?: string | null;
  userId?: string;
  userNickname?: string;
};

export const ProviderProfileComponent: FC<TProviderProfileComponent> = ({
  userId,
  userAvatar,
  userNickname,
}) => {
  const { data: providers } = useGetProvidersQuery({
    client_id: CLIENT_ID,
    onlyActive: true,
    action: EGetProviderAction.auth,
  });
  const [bindEtereumAccount] = useBindEthereumAccountMutation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [defaultProviders, setDefaultProviders] = useState(providers);
  const [providerModal, setProviderModal] = useState(false);
  const [externalAccountOwner, setExternalAccountOwner] = useState('');
  const windowRef = useRef<Window | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let intervalId: ReturnType<typeof setTimeout>;
  const cookies = new Cookies();
  const [providerTemplate, setProviderTemplate] = useState({
    avatar: '',
    client_id: '',
    url: '',
    type: 'EMAIL',
    scopes: '',
    name: '',
    pathToAvatar: '',
    provider_id: '',
    redirect_uri: `${BACKEND_URL}/api/interaction/code`,
  });
  const [getNonce] = useLazyGetNonceQuery();
  const [getOauthUrl] = useGetOauthMutation();

  const externalAccount = async (
    providerUrl: string,
    clientId: string,
    providerId: string,
    providerScopes?: string,
    rebind?: boolean,
  ) => {
    const access = await getAccessToken();
    cookies.set('provider_id', providerId, { path: '/api/interaction/code' });
    cookies.set('client_id', CLIENT_ID, { path: '/api/interaction/code' });
    cookies.set('access_token', access, { path: '/api/interaction/code' });
    cookies.set('user_id', userId, { path: '/api/interaction/code' });
    cookies.set('rebind', rebind, { path: '/api/interaction/code' });

    const result = await getOauthUrl({
      provider_id: providerId,
      state: randomString(30),
      return_url: true,
    });

    windowRef.current = window.open(
      'error' in result ? '' : result.data.url,
      '_blank',
      ` left=${screen.width}, top=` + (screen.height - 470) / 2 + ` width=500, height=500`,
    );

    intervalId = setInterval(() => {
      const urlSearchParams = new URLSearchParams(windowRef.current?.location?.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.result === 'true') {
        clearInterval(intervalId);
        windowRef.current?.close();
        dispatch(userApi.util.invalidateTags(['ExternalAccounts']));
        navigate('/profile');
      } else if (params.result === 'false') {
        clearInterval(intervalId);
        windowRef.current?.close();
        setProviderModal(false);
        switch (params.cause) {
          case 'binded_to_this_user': {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Аккаунт уже привязан к этому профилю.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          case 'binded_to_blocked_user': {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message:
                  'Не удалось привязать внешний аккаунт: он привязан к заблокированному аккаунту.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          case 'binded_to_another_user': {
            return setExternalAccountOwner(`oauth:${params.nickname}`);
          }

          default:
            dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Ошибка привязки',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
            setExternalAccountOwner('');
        }
      } else if (windowRef.current?.closed) {
        dispatch(
          setNotice({
            error: 'Ошибка',
            id: Math.random(),
            isRead: false,
            message: 'Операция прервана пользователем',
            timestamp: new Date().toString(),
          }),
        );
        setProviderModal(false);
        setExternalAccountOwner('');
        clearInterval(intervalId);
      }
    }, 250);
  };

  const authByEthereum = async (rebind?: boolean) => {
    if (window.ethereum) {
      const address = await getEthereumAddress();
      if (address) {
        const { data } = await getNonce(address);

        if (userId && data?.nonce) {
          const sign = await getEthereumSign(address, data?.nonce);
          const bindEthereumAccountResponse = await bindEtereumAccount({
            userId,
            address,
            signature: sign,
            rebind,
            client_id: CLIENT_ID,
          }).unwrap();

          if (bindEthereumAccountResponse.binded_to_this_user) {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Аккаунт уже привязан к этому профилю.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          if (!bindEthereumAccountResponse.success)
            return setExternalAccountOwner(`ethereum:${bindEthereumAccountResponse.nickname}`);
        }
      }
      navigate('/profile');
    } else {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message: 'Установите Metamask',
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const getEthereumAddress = async (): Promise<string | undefined> => {
    try {
      await window?.ethereum?.request({ method: 'eth_requestAccounts' });
      const accounts = await window?.ethereum?.request({ method: 'eth_accounts' });

      if (accounts?.length) return accounts[0];
      return undefined;
    } catch (e) {
      console.log('getEthereumAddress error: ', e);
    }
  };

  const getEthereumSign = async (address: string, nonce: string) => {
    try {
      const hashedMessage = `0x${Buffer.from(
        'Чтобы авторизоваться в сервисе ' +
          PROJECT_NAME +
          ', подпишите случайно сгенерированный текст: ' +
          nonce,
        'utf8',
      ).toString('hex')}`;

      const sign = await window?.ethereum?.request({
        method: 'personal_sign',
        params: [hashedMessage, address, ''],
      });

      return sign;
    } catch (e) {
      console.log('getEthereumSign error: ', e);
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const filteredProviders = providers?.filter((provider) => {
      return provider.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setDefaultProviders(filteredProviders);
  }, [searchValue]);

  const closeWindow = () => {
    setProviderModal(false);
    windowRef.current?.close();
  };

  const closeRebindModal = () => setExternalAccountOwner('');

  const itemsProviders = (defaultProviders || providers)
    ?.map((provider) => {
      return (
        <ListItem
          key={provider.id}
          className={styles.provider}
          style={{cursor: 'pointer'}}
          onClick={() => {
            if (provider.type === ProviderType.PHONE) return navigate('/profile/phone/add');
            if (provider.type === 'EMAIL') {
              navigate('/profile/email/add');
            } else if (provider.type === ProviderType.SMS) {
              navigate(`/profile/sms/add/${provider.id}`);
            } else {
              const providerCopy = { ...provider } as TOauthProvider;
              setProviderTemplate({
                ...providerTemplate,
                url: providerCopy.authorization_endpoint,
                type: providerCopy.type,
                client_id: providerCopy.external_client_id,
                provider_id: providerCopy.id,
                scopes: providerCopy.scopes || '',
                name: providerCopy.name,
              });
              setProviderModal(true);
              externalAccount(
                (provider as TOauthProvider).params.authorization_endpoint,
                (provider as TOauthProvider).params.external_client_id,
                (provider as TOauthProvider).id,
                (provider as TOauthProvider).params.scopes,
              );
            }
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
            }}
            className={styles['provider-icon-wrapper']}
          >
            {provider.type === 'EMAIL' ? <MailIcon /> : !provider.avatar && <IdIcon />}
          </div>
          <CustomTypography className={clsx('text-14', styles['provider-name'])}>
            {provider.name || 'OpenID Connect'}
          </CustomTypography>
        </ListItem>
      );
    });

  return (
    <div className={'wrapper-scroll'}>
      <div className={'content'}>
        <div className={styles['profile-provider']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('text-24-medium', 'font-golos')}
          >
            Добавить способ входа!!!!!
          </CustomTypography>
          <CustomTypography
            color="secondary"
            style={{ marginBottom: 8 }}
            className={clsx('text-14')}
          >
            {itemsProviders?.length
              ? 'Выберите способ входа, который хотите добавить в свой профиль:'
              : 'Доступных способов входа нет'}
          </CustomTypography>
          {providers?.length && providers.length >= 10 && (
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ marginTop: 8 }}
              className={clsx(styles.search, 'custom')}
              variant="standard"
              placeholder="Поиск"
              sx={{ '& .Mui-focused svg': { left: 12 } }}
              InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
              inputProps={{ className: styles.input }}
            />
          )}
          <div className={styles.providers}>
            {itemsProviders?.length ? itemsProviders : ''}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
              <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
                Отмена
              </Button>
            </div>
          </div>
        </div>
        <Modal onClose={closeWindow} open={providerModal}>
          <div className={styles['provider-modal']}>
            <div className={styles['provider-modal-header']}>
              {userAvatar ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(userAvatar)})`,
                    borderRadius: '50%',
                  }}
                  className={styles['provider-icon-modal']}
                />
              ) : (
                <Avatar className={styles['provider-icon-modal']}>
                  <AvatarIcon />
                </Avatar>
              )}
              <div
                style={{
                  backgroundImage: `url(${BACKEND_URL + '/' + providerTemplate.avatar})`,
                }}
                className={styles['provider-icon-modal']}
              >
                {!providerTemplate.avatar && <IdIcon />}
              </div>
              <IconButton onClick={closeWindow} className={styles['provider-close-modal']}>
                <CloseIcon />
              </IconButton>
            </div>
            <CustomTypography className={styles['provider-modal-content']}>
              Войдите в аккаунт {providerTemplate.name} и подтвердите добавление аккаунта в профиль{' '}
              {userNickname}.
            </CustomTypography>
          </div>
        </Modal>

        <ConfirmationModal
          isOpen={!!externalAccountOwner}
          onClose={closeRebindModal}
          onAction={() => {
            externalAccountOwner.split(':')[0] === 'ethereum'
              ? authByEthereum(true)
              : externalAccount(
                  providerTemplate.url,
                  providerTemplate.client_id,
                  providerTemplate.provider_id,
                  providerTemplate.scopes,
                  true,
                );
          }}
          title="Сохранение изменений"
          mainMessage={[`Внешний аккаунт уже привязан к аккаунту ${externalAccountOwner.split(':')[1]}`]}
          actionButtonText="Перепривязать"
        />
      </div>
    </div>
  );
};

export const ProviderProfile = connect(mapStateToProps)(ProviderProfileComponent);
