import React, { FC } from 'react';
import styles from './edit/EditProvider.module.css';
import clsx from 'clsx';
import Switch from '@mui/material/Switch';
import { CLIENT_ID, PROJECT_NAME } from '../../../constants';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTypography } from '../../custom/CustomTypography';
import { addMethodsType, addProviderType } from '../Settings';

type TProviderFooter = {
  clientId: string;
  type: addProviderType | addMethodsType;
};

const ProviderFooterComponent: FC<TProviderFooter> = ({ clientId, type }) => {
  const { control } = useFormContext();
  return (
    <>
      {clientId === CLIENT_ID && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Публичный способ входа
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Способ входа будет доступен для добавления в пользовательские приложения
            </CustomTypography>
          </div>
          <Controller
            name="is_public"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                data-id="is-public-switch"
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      {type !== addMethodsType.QRCODE && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Требовать подтверждение паролем
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              После авторизации во внешней системе у пользователя будет запрошен пароль от{' '}
              {PROJECT_NAME}
            </CustomTypography>
          </div>
          <Controller
            name="password_required"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      {(type === addProviderType.LDAP ||
        type === addProviderType._1C ||
        type === addProviderType.IDM) && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Запретить сброс пароля
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Пользователь не сможет восстановить пароль от учетной записи для данного способа входа
            </CustomTypography>
          </div>
          <Controller
            name="disable_password_reset"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Switch
                classes={{ root: styles['switch-root'] }}
                disableRipple
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export const ProviderFooter = ProviderFooterComponent;
