import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { ElementType, FC } from 'react';
import { useDispatch } from 'react-redux';
import { formatDate, getImageURL } from 'src/helpers';
import { setNotice } from 'src/redux/noticesSlice';
import { EGetProviderAction, useGetProvidersQuery } from 'src/redux/services/provider';
import { TUserWithRole } from 'src/redux/services/users';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { IconWrapper } from '../shared/IconWrapper';
import styles from './ClientDetailsAddInfo.module.css';

interface IAddInfoProps {
  client: {
    domain?: string;
    created_at?: string;
    is_visible?: boolean;
    client_id?: string;
    client_secret?: string;
  };
  users?: TUserWithRole[];
}

const AddInfoComponent: FC<IAddInfoProps> = ({ client, users }) => {
  const { domain, created_at, is_visible, client_id, client_secret } = client;
  const dispatch = useDispatch();
  const { data: providers } = useGetProvidersQuery({
    client_id: client_id ?? '',
    onlyActive: true,
    action: EGetProviderAction.change,
  });

  const items: { icon: ElementType<SvgIconProps>; text: string; copyValue: string }[] = [];
  const dataToCheck = [
    { condition: domain, icon: InsertLinkOutlinedIcon, text: domain, copyValue: domain },
    {
      condition: created_at,
      icon: TodayOutlinedIcon,
      text: formatDate(created_at ?? ''),
      copyValue: '',
    },
    {
      condition: is_visible !== undefined,
      icon: BookmarksOutlinedIcon,
      text: is_visible ? 'Отображается в каталоге' : 'Не отображается в каталоге',
      copyValue: '',
    },
    {
      condition: client_id,
      icon: BadgeOutlinedIcon,
      text: 'Идентификатор (client_id)',
      copyValue: client_id,
    },
    {
      condition: client_secret,
      icon: KeyOutlinedIcon,
      text: 'Секретный ключ (client_secret)',
      copyValue: client_secret,
    },
    {
      condition: users?.length.toString(),
      icon: PeopleAltOutlinedIcon,
      text: `Участников: ${users?.length}`,
      copyValue: '',
    },
  ];

  dataToCheck.forEach(({ condition, icon, text, copyValue }) => {
    if (condition && text) {
      items.push({ icon, text, copyValue: copyValue ?? '' });
    }
  });

  const textWrapper = (text: string, isText: boolean) => {
    if (isText) {
      return <CustomTypography className="text-14">{text}</CustomTypography>;
    } else {
      return (
        <Link className={styles.link} href={client?.domain}>
          {client?.domain}
        </Link>
      );
    }
  };

  const handleCopy = (value: string, text: string) => {
    navigator.clipboard.writeText(value);
    dispatch(
      setNotice({
        id: Math.random(),
        isRead: false,
        message: `${text} скопирован`,
        timestamp: new Date().toString(),
      }),
    );
  };

  return (
    <Box className={styles.addInfoContent}>
      <div className={styles.addInfoList}>
        {items.map((item, index) => (
          <div key={index} className={styles.addInfoItem}>
            <IconWrapper>
              <CustomMuiIcon color="grey" Icon={item.icon} />
            </IconWrapper>
            {textWrapper(item.text, item.copyValue !== domain)}
            {!!item.copyValue && (
              <Button
                data-id="copy-button"
                className={styles.buttonCopy}
                onClick={() => handleCopy(item.copyValue, item.text)}
              >
                <CustomMuiIcon
                  color="grey"
                  Icon={ContentCopyOutlinedIcon}
                  className={styles.iconCopy}
                />
              </Button>
            )}
          </div>
        ))}
      </div>
      <Box>
        <div className={styles.divider} />
        <CustomTypography color="grey" className="text-14">
          Активные способы входа:
        </CustomTypography>
        <Box className={styles.providers}>
          {providers?.length ? (
            providers.map((item) => (
              <div key={item?.id}>
                <IconWithTooltip
                  customIcon={
                    item?.avatar ? (
                      <Avatar src={getImageURL(item?.avatar)} className={styles.providerAvatar} />
                    ) : undefined
                  }
                  customStyleButton={styles.providerButton}
                  muiIconStyles={styles.providerAvatar}
                  muiIcon={LayersOutlinedIcon}
                  customTitle={item?.name}
                />
              </div>
            ))
          ) : (
            <CustomTypography color="error" className="text-14">Отсутствуют</CustomTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ClientDetailsAddInfo = (AddInfoComponent);
