import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { Roles } from '../../enums';
import { getObjectKeys } from '../../helpers';
import { getAccessToken } from '../../service/auth';
import { TCustomFields } from '../userSlice';
import { IEmailTemplate } from 'src/components/applications/old/EmailTemplate';

export type TSettings = {
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  allowed_symbols: string;
  ldap_password_error: string;
  allowed_special_symbols: string;
  min_uppercase_count: number;
  number: number;
  spec_char: number;
  length_char_min: number;
  length_char_max: number;
  max_age: number;
  min_age: number;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  allowed_login_fields: string;
  email_templates: IEmailTemplate[];
  sentry: TSentry;
};

export type TSentry = {
  /**
   * DSN
   */
  dsn: string;
  /**
   * Включен ли Sentry
   */
  enabled: boolean;
  /**
   * ID пользователя, по действиям которого нужно отправлять трассировки и ошибки
   */
  user_id: string;
};

export type TUpdateSettings = Partial<TSettings>;

export type TClient = {
  name: string;
  client_id: string;
  grant_types: string[];
  created_at: string;
  avatar: string | null;
  scopes?: string;
  cover?: string | null;
  client_secret: string;
  description?: string;
  domain: string;
  widget_title: string;
  post_logout_redirect_uris: string[];
  request_uris: string[];
  response_types: string[];
  redirect_uris: string[];
  widget_colors: { button_color: string; font_color: string; link_color: string };
  is_visible: boolean;
  refresh_token_ttl?: string;
  access_token_ttl?: string;
  registration_access_token: { iat: number; clientId: string; kind: string; jti: string };
  show_avatar_in_widget: boolean;
  hide_widget_header: boolean;
  hide_widget_footer: boolean;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  required_providers_ids: string[];
} & Partial<TSettings>;

export type TApplication = {
  role?: Roles.OWNER | Roles.EDITOR;
  client: TClient;
};

export type TUserCount = {
  userCount: number;
  adminCount: number;
  ownerCount: number;
};

export type TUser = {
  id: number;
  sub: string;
  email: string;
  email_verified: string;
  birthdate: string;
  family_name: string;
  gender: string;
  given_name: string;
  locale: string;
  login: string;
  middle_name: string;
  name: string;
  nickname: string;
  phone_number: string;
  phone_number_verified: string;
  picture: string;
  preferred_username: string;
  profile: string;
  updated_at: Date;
  website: string;
  zoneinfo: string;
  hashed_password: string;
  blocked: boolean;
  deleted: string;
  profile_claims_privacy: string[];
  custom_fields: TCustomFields;
  password_updated_at?: string;
};

export const clientApi = createApi({
  reducerPath: 'clientApi',
  tagTypes: ['ManagedApps', 'AppsWithPermissions', 'SearchHistory', 'ClientDetails'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getApplications: builder.query<
      TApplication[],
      {
        user_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: ({
        user_id,
        search_string,
        sort_by,
        sort_direction,
        number_of_records,
        number_of_skip,
        last_record_id,
      }) => {
        return {
          url: `client/v1/get_clients/${user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            search_string,
            sort_by,
            sort_direction,
            number_of_records,
            number_of_skip,
            last_record_id,
          },
        };
      },
    }),

    getApplicationById: builder.query<TApplication, { user_id: string; client_id: string }>({
      queryFn: async ({ client_id }, { getState }, extraOptions, baseQuery) => {
        const { data } = await baseQuery({
          url: `${BACKEND_URL}/api/v1/clients/${client_id}`,
        });
        return { data: data as TApplication };
      },
      providesTags: ['ClientDetails'],
    }),

    getClientByScopes: builder.query<
      TApplication,
      {
        user_id: string;
        client_id: string;
      }
    >({
      query: ({ user_id, client_id }) => {
        return {
          url: `client/v1/client_by_scopes/${client_id}/${user_id}`,
        };
      },
    }),

    getClientsByScopes: builder.query<
      TApplication[],
      {
        user_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: ({
        user_id,
        search_string,
        sort_by,
        sort_direction,
        number_of_records,
        number_of_skip,
        last_record_id,
      }) => {
        return {
          url: `client/v1/clients_by_scopes/${user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            search_string,
            sort_by,
            sort_direction,
            number_of_records,
            number_of_skip,
            last_record_id,
          },
        };
      },
    }),

    deleteSessionByClient: builder.query({
      query: ({ userId, clientId }) => ({
        method: 'DELETE',
        url: `client/v1/session_by_client/${userId}/${clientId}`,
      }),
    }),

    getUserCount: builder.query<
      TUserCount,
      {
        selectedAppId: string;
        search_string: string;
        search_filter?: string[];
        ignore_ids?: number[];
      }
    >({
      query: ({ selectedAppId, ...body }) => {
        return {
          url: `client/v1/users/count/${selectedAppId}`,
          method: 'POST',
          body,
        };
      },
    }),

    getApplicationsCount: builder.query<number, { userId: string; searchString?: string }>({
      query: ({ userId, searchString }) => {
        return {
          url: `client/v1/count/${userId}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { search_string: searchString },
        };
      },
    }),

    getApplicationsCountByScopes: builder.query<number, { userId: string; searchString?: string }>({
      query: ({ userId, searchString }) => {
        return {
          url: `client/v1/clients_by_scopes/count/${userId}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { search_string: searchString },
        };
      },
    }),

    createApplication: builder.mutation({
      query: (requestParams: TRequestParams) => {
        return {
          url: 'v1/clients',
          method: 'POST',
          body: clientParamsToFormData(requestParams),
        };
      },
      invalidatesTags: [{ type: 'ManagedApps', id: 'LIST' }],
    }),

    updateApplication: builder.mutation<
      number,
      {
        currentClientId: string;
        params: Partial<TRequestParams & { client_id: string; client_secret?: string }>;
      }
    >({
      query: ({ currentClientId, params }) => ({
        url: `v1/clients/${currentClientId}`,
        method: 'PUT',
        body: clientParamsToFormData(params),
      }),
      invalidatesTags: [{ type: 'ManagedApps', id: 'LIST' }, 'ClientDetails'],
    }),

    changeUsersRole: builder.mutation<
      void,
      {
        client_id: string;
        role: Roles.ADMIN | Roles.USER | Roles.EDITOR | Roles.OWNER;
        checked_ids?: number[];
        all?: boolean;
        unchecked_ids?: number[];
        search_string?: string;
        search_filter?: string[];
      }
    >({
      query: (body) => {
        return {
          url: `client/v1/roles/${body.client_id}`,
          method: 'PUT',
          body,
        };
      },
    }),

    deleteApplication: builder.mutation({
      query: (clientId: string) => ({
        url: `v1/clients/${clientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ManagedApps', id: 'LIST' }],
    }),

    revokeScopes: builder.mutation({
      query: ({ userId, clientId }) => ({
        url: `client/v1/revoke_scopes/${userId}/${clientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ManagedApps', id: 'LIST' }],
    }),

    getScopes: builder.query<
      { scopes?: string; createdAt?: string } | undefined,
      { userId: string; clientId: string }
    >({
      query: ({ userId, clientId }) => 'client/v1/scopes/' + userId + '/' + clientId,
      transformResponse: (response: { scopes?: string; created_at?: string } | null) => ({
        scopes: response?.scopes,
        createdAt: response?.created_at,
      }),
    }),

    getUsers: builder.query<
      { user: TUser; role: Roles; profile_claims_privacy: string[] }[],
      {
        client_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        last_record_user_id?: string;
        number_of_skip?: string;
        search_param_user_id?: string;
        search_filter?: string[];
      }
    >({
      query: ({ client_id, ...requestParams }) => {
        let body = '';

        getObjectKeys(requestParams).forEach(
          (paramKey) => (body += paramKey + '=' + requestParams[paramKey] + '&'),
        );

        return {
          url: `v1/clients/${client_id}/users`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded ',
          },
          body,
        };
      },
    }),

    appActivated: builder.query<boolean, void>({
      query: () => 'client/v1/activated',
    }),
  }),
});

export type TRequestParams = {
  name: string;
  redirect_uris: string[];
  required_providers_ids?: string[];
  user_id?: string;
  post_logout_redirect_uris: string[];
  request_uris?: string[];
  response_types?: string[];
  grant_types: string[];
  avatar: File | null;
  domain: string;
  description: string;
  registration_access_token?: string;
  widget_colors?: { font_color: string; link_color: string; button_color: string };
  show_avatar_in_widget?: boolean;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
};

export const clientParamsToFormData = (requestParams: Partial<TRequestParams>) => {
  try {
    return getObjectKeys(requestParams).reduce((acc, key) => {
      if (
        key === 'redirect_uris' ||
        key === 'post_logout_redirect_uris' ||
        key === 'grant_types' ||
        key === 'request_uris' ||
        key === 'response_types'
      ) {
        requestParams[key]?.forEach((uri) => {
          acc.append(key + '[]', uri);
        });
        return acc;
      }

      if (key === 'required_providers_ids') {
        if (!requestParams[key]?.length) acc.append(key + '[]', '');
        else
          requestParams[key]?.forEach((id) => {
            acc.append(key + '[]', id);
          });
        return acc;
      }

      if (key === 'widget_colors') {
        if (requestParams.widget_colors) {
          getObjectKeys(requestParams.widget_colors).forEach((item) =>
            acc.append(item, requestParams.widget_colors?.[item] || ''),
          );

          return acc;
        }

        return acc;
      }

      const requestParam = requestParams[key];

      if (typeof requestParam === 'boolean' || typeof requestParam === 'number') {
        acc.append(key, String(requestParam));
        return acc;
      }

      acc.append(key, requestParam || '');
      return acc;
    }, new FormData());
  } catch (e) {
    console.log('clientParamsToFormData error: ' + (e as Error).message);
  }
};

export const {
  useGetClientsByScopesQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useDeleteApplicationMutation,
  useRevokeScopesMutation,
  useGetScopesQuery,
  useGetUsersQuery,
  useLazyGetApplicationsQuery,
  useLazyGetClientsByScopesQuery,
  useLazyGetApplicationsCountQuery,
  useLazyGetApplicationsCountByScopesQuery,
  useLazyGetUsersQuery,
  useLazyGetUserCountQuery,
  useGetUserCountQuery,
  useLazyGetScopesQuery,
  useLazyDeleteSessionByClientQuery,
  useGetApplicationByIdQuery,
  useChangeUsersRoleMutation,
  useAppActivatedQuery,
  useGetClientByScopesQuery,
} = clientApi;
