import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ProviderType,
  TCreateProvider,
  TIdmParams,
  useCreateProviderMutation,
} from '../../../../redux/services/provider';
import styles from './CreateProvider.module.css';

import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import { isObjectEmpty, isOwner } from '../../../../helpers';
import { RootState } from '../../../../redux/rootReducer';
import { CustomTypography } from '../../../custom/CustomTypography';
import { ConfirmationModal } from '../../../modal/ConfirmationModal';
import { ModalCloseOnly } from '../../../modal/ModalCloseOnly';
import { ProviderHeader } from '../../../Providers/ProviderHeader';
import { SharedFormProvider } from '../../../shared/FormProvider';
import { ProviderSettingsSidePanel } from '../../../sidePanel/ProviderSettingsSidePanel';
import { ProviderFooter } from '../TrustedProviderFooter';
import { addProviderType } from '../../Settings';

export type CreateIdmProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  groups: string;
  auto_registration?: boolean;
  mapping: string;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера IDM не может превышать 255 символов')
    .url('Введите корректный адрес'),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  disable_password_reset: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateIdmProvider: FC<TCreateProvider> = ({ isOpen, onClose, pathToAvatar }) => {
  const methods = useForm<CreateIdmProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      disable_password_reset: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) onClose(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) onClose();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateIdmProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          params: TIdmParams;
          avatar: string;
          type: addProviderType.IDM;
        }
      > = JSON.parse(text);
      const { type, avatar, params, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== addProviderType.IDM) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (params) {
          (Object.keys(params) as Array<keyof TIdmParams>).forEach((field) => {
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (restInputs) {
          (Object.keys(restInputs) as Array<keyof Omit<CreateIdmProviderInputs, 'avatar'>>).forEach(
            (field) => {
              if (
                field === 'auto_registration' ||
                field === 'auth_without_email' ||
                field === 'password_required' ||
                field === 'is_public' ||
                field === 'disable_password_reset'
              ) {
                return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
              }
              if (
                field === 'name' ||
                field === 'description' ||
                field === 'groups' ||
                field === 'mapping' ||
                field === 'path_to_avatar' ||
                field === 'url'
              ) {
                setValue(field, restInputs?.[field] || '', { shouldDirty: true });
              }
            },
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateIdmProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        type: addProviderType.IDM,
        ...rest,
        avatar: avatar ? avatar : null,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа IDM"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<CreateIdmProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle="Создать"
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            pathToAvatar={pathToAvatar}
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес сервера IDM
          </CustomTypography>
          <TextField
            {...register('url', {
              required: true,
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('url', event.target.value.trim());
              },
              onChange: () => {
                if (errors.url) clearErrors('url');
              },
            })}
            className="custom"
            error={!!errors.url}
            helperText={errors.url ? errors.url.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес сервера IDM
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Группы поиска IDM
          </CustomTypography>
          <TextField
            {...register('groups', {
              required: true,
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('groups', event.target.value.trim());
              },
              onChange: () => {
                if (errors.groups) clearErrors('groups');
              },
            })}
            className="custom"
            error={!!errors.groups}
            helperText={errors.groups ? errors.groups.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Название групп IDM.Trusted.Net через &quot;,&quot;
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Сопоставление атрибутов IDM
          </CustomTypography>
          <TextField
            {...register('mapping', {
              required: true,
              onChange: () => {
                if (errors.mapping) clearErrors('mapping');
              },
            })}
            className="custom"
            error={!!errors.mapping}
            helperText={errors.mapping ? errors.mapping.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Сопоставление атрибутов профиля пользователя Trusted.ID с атрибутами пользователя
            внешней системы в формате: given_name:givenName, family_name:surname, email:email,
            picture:photo
          </CustomTypography>
          <ProviderFooter type={addProviderType.IDM} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ConfirmationModal
        title="Сохранение изменений"
        mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
        actionButtonText="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          onClose();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для IDM-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
