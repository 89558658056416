import React, { FC } from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import styles from './CustomRadioButton.module.css';
import { FormControlLabel, FormControlLabelProps, Radio, useTheme } from '@mui/material';
import clsx from 'clsx';

export const CustomRadioButton: FC<Omit<FormControlLabelProps, 'control'>> = ({ ...props }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      classes={{ root: styles.root, label: styles.label }}
      {...props}
      sx={{
        ...props.sx,
        ...{
          '& .MuiTypography-root': {
            color: theme.palette.custom.main,
          },
        },
      }}
      control={
        <Radio
          sx={{
            '&.Mui-checked path': {
              fill: theme.palette.custom.main,
            },
          }}
          disableRipple
          className={clsx(styles.radio, props.className)}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
        />
      }
    />
  );
};
