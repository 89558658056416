/* eslint-disable no-useless-escape */
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { ChangeEvent, Dispatch, FC, FocusEvent, SetStateAction } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CustomMuiIcon } from 'src/components/custom/CustomMuiIcon';
import { BACKEND_URL, CLIENT_ID, LOGO_URL } from '../../../constants';
import { ReactComponent as AppIcon } from '../../../icons/App.svg';
import { TClient } from '../../../redux/services/client';
import { TMiscProvider, TOauthProvider } from '../../../redux/services/provider';
import { TProviderRule } from '../../../redux/services/settings';
import {
  EAuthMethodType,
  EGrantTypes,
  EResponseTypes,
  ESigningAlgTypes,
  ESubjectTypeVariant,
} from '../../applications/old/CreateApplication';
import { CustomSelect } from '../../custom/CustomSelect';
import { CustomTypography } from '../../custom/CustomTypography';
import { PasswordTextfield } from '../../custom/PasswordTextfield';
import { TClientSettingsInputs } from './ClientSettings';
import styles from './ClientSettings.module.css';
import { DescriptionField, RedirectUrisField } from './ClientSettingsFields';
import { UploadAndDisplayImage } from './UploadAndDisplayImage';
import { UploadCroppedImage } from './UploadCroppedImage';

type IClientSettingsHeaderProps = {
  client: TClient;
  avatarSrc: string | null;
  coverSrc: string | null;
  setAvatarSrc: Dispatch<SetStateAction<string | null>>;
  openedFromMenu?: string;
  setCoverSrc: Dispatch<SetStateAction<string | null>>;
};

export interface TProviderItem {
  rule: TProviderRule;
  provider: TOauthProvider | TMiscProvider;
}

export const ClientSettingsHeader: FC<IClientSettingsHeaderProps> = ({
  client,
  avatarSrc,
  coverSrc,
  setAvatarSrc,
  openedFromMenu,
  setCoverSrc,
}) => {
  const {
    register,
    control,
    clearErrors,
    setValue,
    setError,
    getValues,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<TClientSettingsInputs>();

  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirect_uris',
  });
  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'post_logout_redirect_uris',
  });
  const {
    fields: requestUris,
    append: requestAppend,
    remove: requestRemove,
  } = useFieldArray({
    control,
    name: 'request_uris',
  });
  const watchResponseTypes = watch('response_types');
  const watchGrantTypes = watch('grant_types');
  const watchClientSecret = watch('client_secret');
  const isAdminClient = client.client_id === CLIENT_ID;

  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setCoverValue = (value: File | null) => setValue('cover', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const setCoverError = (error: string) => setError('cover', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const clearCoverError = () => clearErrors('cover');

  return (
    <>
      {!openedFromMenu && (
        <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
          Настройки приложения {getValues('name')}
        </CustomTypography>
      )}
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Основная информация
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails className={styles['accordion-details']}>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Название приложения
          </CustomTypography>
          <TextField
            {...register('name', {
              required: true,
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('name', event.target.value.trim());
              },
              onChange: () => {
                if (errors.name) clearErrors('name');
              },
            })}
            className="custom"
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            fullWidth
            variant="standard"
            disabled={isAdminClient}
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Имя приложения, отображаемое пользователям
          </CustomTypography>
          {!isAdminClient && (
            <>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Описание приложения
              </CustomTypography>
              <TextField
                {...register('description', {
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value.length > 255) {
                      setError('description', {
                        message: 'Невозможно ввести более 255 символов',
                        type: 'validate',
                      });
                      setValue('description', event.target.value.slice(0, 255));
                    } else if (errors.description) {
                      clearErrors('description');
                    }
                  },
                })}
                className="custom"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
                fullWidth
                variant="standard"
                multiline
              />
              <DescriptionField control={control} />
            </>
          )}
          <div className={styles['upload-file']}>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Логотип приложения
            </CustomTypography>
            <UploadAndDisplayImage
              maxImageSize={1}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              componentName={'logo-image'}
              setAvatarValue={setAvatarValue}
              DefaultIcon={<AppIcon fill="#ced0d9" />}
              pathToAvatar={isAdminClient ? LOGO_URL.replace(`${BACKEND_URL}/`, '') : null}
              disabled={isAdminClient}
            />
            {errors.avatar && (
              <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
                {errors.avatar.message}
              </CustomTypography>
            )}
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Обложка приложения
            </CustomTypography>
            <UploadCroppedImage
              error={errors.cover?.message}
              maxImageSize={5}
              setAvatarError={setCoverError}
              imgSrc={coverSrc}
              setImgSrc={setCoverSrc}
              clearAvatarError={clearCoverError}
              defaultValue={client?.cover || null}
              componentName={'cover-image'}
              setAvatarValue={setCoverValue}
              pathToAvatar={null}
            />
            {errors.cover && (
              <CustomTypography color="error" className={clsx('text-14', styles['input-error'])}>
                {errors.cover.message}
              </CustomTypography>
            )}
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Файл с расширением .jpg, .jpeg, .png. Максимальный размер - 5 МБ.
            </CustomTypography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Параметры приложения
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails className={styles['accordion-details']}>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Идентификатор (client_id)
          </CustomTypography>
          <TextField
            className="custom"
            error={!!errors.client_id}
            helperText={errors.client_id ? errors.client_id.message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    title="Копировать"
                    classes={{
                      tooltip: styles['input-tooltip'],
                      arrow: styles['input-tooltip-arrow'],
                    }}
                  >
                    <Button
                      className={styles['input-adornment-button']}
                      onClick={() => {
                        if (client) navigator.clipboard.writeText(client.client_id);
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </Button>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="standard"
            {...register('client_id', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 255) {
                  setError('client_id', {
                    message: 'Невозможно ввести более 255 символов',
                    type: 'validate',
                  });
                  setValue('client_id', event.target.value.slice(0, 255));
                } else if (errors.client_id) {
                  clearErrors('client_id');
                }
              },
            })}
            disabled={isAdminClient}
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор приложения
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Секретный ключ (client_secret)
          </CustomTypography>
          <PasswordTextfield
            style={{ marginBottom: 32 }}
            value={watchClientSecret}
            className="custom"
            error={!!errors.client_secret}
            helperText={errors.client_secret ? errors.client_secret.message : ''}
            fullWidth
            variant="standard"
            id="copy"
            {...register('client_secret', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 255) {
                  setError('client_secret', {
                    message: 'Невозможно ввести более 255 символов',
                    type: 'validate',
                  });
                  setValue('client_secret', event.target.value.slice(0, 255));
                } else if (errors.client_secret) {
                  clearErrors('client_secret');
                }
              },
            })}
            disabled={isAdminClient}
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес приложения
          </CustomTypography>
          <TextField
            {...register('domain', {
              onChange: () => {
                if (errors.domain) clearErrors('domain');
              },
            })}
            className="custom"
            error={!!errors.domain}
            helperText={errors.domain ? errors.domain.message : ''}
            fullWidth
            variant="standard"
            disabled={isAdminClient}
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес приложения в формате «протокол://доменное имя:порт»
          </CustomTypography>
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>Отображать в каталоге</CustomTypography>
            <Controller
              control={control}
              name="is_visible"
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              )}
            />
          </div>
          <>
            {redirectUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <CustomTypography
                    className={clsx(
                      'text-14',

                      styles.asterisk,
                      styles['input-title'],
                    )}
                  >
                    Возвратный URL #{index + 1} (Redirect_uri)
                  </CustomTypography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`redirect_uris.${index}.value`, {
                        onChange: () => {
                          if (errors.redirect_uris?.[index])
                            clearErrors(`redirect_uris.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      onBlur={() => {
                        if (getValues('redirect_uris').every((uri) => !uri.value))
                          setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
                        else {
                          clearErrors(`redirect_uris.0.value`);
                          trigger(`redirect_uris.${index}.value`);
                        }
                      }}
                      variant="standard"
                      error={!!errors.redirect_uris?.[index]}
                      helperText={
                        errors.redirect_uris ? errors?.redirect_uris?.[index]?.value?.message : ''
                      }
                      disabled={isAdminClient}
                    />

                    {redirectUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => redirectRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        control={control}
                        onClick={() => redirectAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                        name="redirect_uris"
                        disabled={isAdminClient}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <CustomTypography
                      className={clsx('text-14', styles['input-subtitle'])}
                      color="grey"
                    >
                      Адрес, на который пользователь переадресовывается после авторизации
                    </CustomTypography>
                  )}
                </div>
              );
            })}
            {redirectUris.length > 1 && (
              <RedirectUrisField
                control={control}
                onClick={() => redirectAppend({ value: '', name: '' })}
                className={clsx(styles['add-button'], styles['add-button-bottom'])}
                name="redirect_uris"
              />
            )}
          </>
          <>
            {logoutUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <CustomTypography className={clsx('text-14', styles['input-title'])}>
                    URL выхода из системы #{index + 1} (post_logout_redirect_uri)
                  </CustomTypography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`post_logout_redirect_uris.${index}.value`, {
                        onChange: () => {
                          if (errors.post_logout_redirect_uris?.[index])
                            clearErrors(`post_logout_redirect_uris.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      error={!!errors.post_logout_redirect_uris?.[index]}
                      helperText={
                        errors.post_logout_redirect_uris
                          ? errors?.post_logout_redirect_uris?.[index]?.value?.message
                          : ''
                      }
                      variant="standard"
                      disabled={isAdminClient}
                    />
                    {logoutUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => logoutRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        control={control}
                        onClick={() => logoutAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                        name="post_logout_redirect_uris"
                        disabled={isAdminClient}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <CustomTypography
                      className={clsx('text-14', styles['input-subtitle'])}
                      style={{ width: '85%' }}
                      color="grey"
                    >
                      Адрес, на который переадресовывается пользователь после выхода. Если значение
                      не указано, то используется «Возвратный URL»
                    </CustomTypography>
                  )}
                </div>
              );
            })}
            {logoutUris.length > 1 && (
              <RedirectUrisField
                control={control}
                onClick={() => logoutAppend({ value: '', name: '' })}
                className={clsx(styles['add-button'], styles['add-button-bottom'])}
                disabled={isAdminClient}
                name="post_logout_redirect_uris"
              />
            )}
          </>
          <>
            {requestUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <CustomTypography className={clsx('text-14', styles['input-title'])}>
                    URL запроса аутентификации или восстановления после аутентификации #{index + 1}{' '}
                    (request_uris)
                  </CustomTypography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`request_uris.${index}.value`, {
                        onChange: () => {
                          if (errors.request_uris?.[index])
                            clearErrors(`request_uris.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      error={!!errors.request_uris?.[index]}
                      helperText={
                        errors.request_uris ? errors?.request_uris?.[index]?.value?.message : ''
                      }
                      variant="standard"
                      disabled={isAdminClient}
                    />
                    <Button
                      variant="custom"
                      color="secondary"
                      onClick={() => {
                        if (requestUris.length > 1) requestRemove(index);
                        else {
                          setValue('request_uris', [{ value: '', name: 'request_uris' }], {
                            shouldDirty: true,
                          });
                          clearErrors(`request_uris.0.value`);
                        }
                      }}
                      className={clsx(styles['delete-button'])}
                    >
                      Удалить
                    </Button>
                  </div>
                </div>
              );
            })}
            <RedirectUrisField
              control={control}
              onClick={() => requestAppend({ value: '', name: '' })}
              className={clsx(styles['add-button'], styles['add-button-bottom'])}
              name="request_uris"
            />
          </>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Тип ответов (response_types)
          </CustomTypography>
          <div className={styles['type-buttons-wrapper']}>
            {Object.values(EResponseTypes).map((type) => (
              <Button
                variant={
                  watchResponseTypes.find((findType) => findType === type)
                    ? 'contained'
                    : 'outlined'
                }
                className={styles.typeButton}
                disabled={
                  isAdminClient ||
                  (type === EResponseTypes.code || type === EResponseTypes.none
                    ? false
                    : !watchGrantTypes.includes(EGrantTypes.implicit))
                }
                onClick={() => {
                  setValue(
                    'response_types',
                    watchResponseTypes.find((findType) => findType === type)
                      ? watchResponseTypes.filter((filterType) => filterType !== type)
                      : [...watchResponseTypes, type],
                    { shouldDirty: true },
                  );
                }}
                key={type}
              >
                {type}
              </Button>
            ))}
          </div>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Типы предоставления доступа (grant_types)
          </CustomTypography>
          <div className={styles['type-buttons-wrapper']}>
            {Object.values(EGrantTypes).map((type) => (
              <Button
                className={styles.typeButton}
                variant={
                  watchGrantTypes.find((findType) => findType === type) ? 'contained' : 'outlined'
                }
                onClick={() => {
                  const newGrantTypes = watchGrantTypes.find((findType) => findType === type)
                    ? watchGrantTypes.filter((filterType) => filterType !== type)
                    : [...watchGrantTypes, type];
                  if (!newGrantTypes.includes(EGrantTypes.implicit))
                    setValue(
                      'response_types',
                      watchResponseTypes.filter(
                        (type) => type === EResponseTypes.code || type === EResponseTypes.none,
                      ),
                    );

                  setValue('grant_types', newGrantTypes, { shouldDirty: true });
                }}
                disabled={isAdminClient}
                key={type}
              >
                {type}
              </Button>
            ))}
          </div>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Метод аутентификации клиента для конечной точки получения токена
            (token_endpoint_auth_method)
          </CustomTypography>
          <Controller
            control={control}
            name="token_endpoint_auth_method"
            defaultValue={EAuthMethodType.client_secret_basic}
            render={({ field }) => (
              <CustomSelect
                disabled={isAdminClient}
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Метод аутентификации, используемый при доступе к конечной точке проверки токена
            (introspection_endpoint_auth_method)
          </CustomTypography>
          <Controller
            control={control}
            name="introspection_endpoint_auth_method"
            defaultValue={EAuthMethodType.client_secret_basic}
            render={({ field }) => (
              <CustomSelect
                disabled={isAdminClient}
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Метод аутентификации, используемый при доступе к конечной точке отзыва токенов
            (revocation_endpoint_auth_method)
          </CustomTypography>
          <Controller
            control={control}
            name="revocation_endpoint_auth_method"
            defaultValue={EAuthMethodType.client_secret_basic}
            render={({ field }) => (
              <CustomSelect
                disabled={isAdminClient}
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Алгоритм подписи, используемый при создании подписанного ID-токена
            (id_token_signed_response_alg)
          </CustomTypography>
          <Controller
            control={control}
            name="id_token_signed_response_alg"
            defaultValue={ESigningAlgTypes.RS256}
            render={({ field }) => (
              <CustomSelect
                disabled={isAdminClient}
                style={{ width: '100%' }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...ESigningAlgTypes }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Проверка наличия времени Аутентификации (require_auth_time)
            </CustomTypography>
            <Controller
              control={control}
              name="require_auth_time"
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              )}
            />
          </div>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Способ передачи ID пользователя в идентификационном токене (subject_type)
          </CustomTypography>
          <Controller
            control={control}
            name="subject_type"
            defaultValue={ESubjectTypeVariant.public}
            render={({ field }) => (
              <CustomSelect
                disabled={isAdminClient}
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {['public', 'pairwise'].map((type) => (
                  <MenuItem key={type} value={type} className="custom-select">
                    {type}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
