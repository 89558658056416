import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ThemeColors } from './Theme';

interface IIconProps {
  Icon: React.ElementType<SvgIconProps>;
  color?: ThemeColors;
  className?: string;
}

const IconComponent: React.FC<IIconProps> = ({
  Icon,
  color,
  className
}) => {
  const theme = useTheme();

  const getColor = () => {
    if (color === 'grey') return theme.palette.custom.mainGrey;
    if (color === 'error') return theme.palette.custom.error;
    if (color === 'link') return theme.palette.custom.link;
    return theme.palette.custom.main;
  };

  return <Icon className={className} sx={{ color: getColor() }} />
};

/**
 * Компонент, который отображает иконку MUI с заготовленным вариантов цвета из темы.
 * @component
 * @param Icon - Компонент иконки (например, Material UI Icon).
 * @param color - Дополнительный цвет (grey, error, link и др.).
 * @param className - Пользовательский CSS-класс для стилизации.
 */
export const CustomMuiIcon = (IconComponent)
