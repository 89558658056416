import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './SidePanel.module.css';
import { Tooltip } from '@mui/material';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

interface ICreateSidePanel {
  children: React.ReactNode;
  handleClosePanel: () => void;
  isOpenPanel: boolean;
  title: string;
  createAction: () => void;
  description?: string;
  isNoBackdrop: boolean;
}
export const CreateSidePanel = ({
  children,
  handleClosePanel,
  isOpenPanel,
  title,
  createAction,
  description,
  isNoBackdrop,
}: ICreateSidePanel) => {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: clsx(isNoBackdrop && 'none') } },
      }}
      onClose={handleClosePanel}
      open={isOpenPanel}
      anchor={isMobile ? 'bottom' : 'right'}
      variant="temporary"
    >
      <div className={styles.header}>
        <div className={styles['title-container']}>
          <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
            {title}
          </CustomTypography>
          <Tooltip title="Создать">
            <Button
              data-id="side-panel-create-button"
              variant="custom"
              component="label"
              color="secondary"
              
              onClick={createAction}
              startIcon={<PostAddOutlinedIcon className={styles['create-button-icon']} />}
            />
          </Tooltip>
        </div>
        <IconButton
          data-id="side-panel-close-button"
          className={styles['close-button']}
          onClick={handleClosePanel}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </div>

      {description && (
        <CustomTypography className={clsx('text-15', styles['description-wrapper'])} color="grey">
          {description}
        </CustomTypography>
      )}
      {children}
    </Drawer>
  );
};
