import styles from './TopTabsApplication.module.css';
import React, { FC, SyntheticEvent, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { RootState } from '../../../redux/rootReducer';
import { CLIENT_ID } from '../../../constants';
import { TUserProfile } from '../../../redux/userSlice';
import { isOwner, isOwnerOrEditor } from '../../../helpers';
import { setApplicationsTab } from '../../../redux/appSlice';
import { useTheme } from '@mui/material';
import { appTabs } from 'src/enums';

type TApplicationsProps = {
  role?: TUserProfile['role'];
  applicationsTab: string;
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.userProfile.role,
  applicationsTab: state.app.applicationsTab,
});

const TopTabsApplicationComponent: FC<TApplicationsProps> = ({ role, applicationsTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const theme = useTheme();

  useEffect(() => {
    if (location.endsWith(`/applications`)) {
      dispatch(setApplicationsTab(appTabs.clients));
    }

    if (location.startsWith(`/applications/${CLIENT_ID}`)) {
      dispatch(setApplicationsTab(appTabs.clients));
    }

    if (
      location.startsWith(`/applications/users`) ||
      location.startsWith(`/applications/user/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/edit/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/external-provider/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/email/add/${CLIENT_ID}`) ||
      location.startsWith(`/applications/user/email/change/${CLIENT_ID}`)
    ) {
      dispatch(setApplicationsTab(appTabs.users));
    }

    if (location.endsWith(`/edit`)) {
      dispatch(setApplicationsTab(appTabs.settings));
    }

    if (location.startsWith(`/applications/event-log`)) {
      dispatch(setApplicationsTab(appTabs.eventLog));
    }
  }, [location]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    dispatch(setApplicationsTab(newValue));
    navigate(newValue);
  };

  return (
    <Box className={styles.applications} sx={{ height: 54, display: 'flex', alignItems: 'center' }}>
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{
          sx: {
            background: theme.palette.custom.main,
            height: '1px',
          },
        }}
        classes={{ flexContainer: styles.tabsContainer }}
        value={applicationsTab}
        onChange={handleChange}
      >
        <Tab
          icon={<LayersOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          label="Приложения"
          id="0"
          value={appTabs.clients}
          onClick={() => navigate('/applications')}
        />
        {isOwnerOrEditor(role) && (
          <Tab
            icon={<SettingsOutlinedIcon />}
            iconPosition="start"
            className={styles.tab}
            label="Настройки"
            id="1"
            value={appTabs.settings}
            onClick={() => navigate(`/applications/${CLIENT_ID}/edit`)}
          />
        )}
        {isOwnerOrEditor(role) && (
          <Tab
            icon={<PeopleAltOutlinedIcon />}
            iconPosition="start"
            className={styles.tab}
            label="Пользователи"
            id="2"
            value={appTabs.users}
            onClick={() => navigate(`/applications/users`, { state: { clientId: CLIENT_ID } })}
          />
        )}
        <Tab
          icon={<TocOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          label="Журнал"
          id="3"
          value={appTabs.eventLog}
          onClick={() => navigate('/applications/event-log')}
        />
      </Tabs>
    </Box>
  );
};

export const TopTabsApplication = connect(mapStateToProps)(TopTabsApplicationComponent);
