import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { isApiErrorWithMessage } from '../../helpers';
import {
  AccountTypes,
  useAddPhoneBySmsMutation,
  useGetExternalAccountsQuery,
} from '../../redux/services/user';
import {
  SmsCodeTypes,
  useLazyCheckPhoneNumberAvialabilityQuery,
  useSendSmsCodeMutation,
} from '../../redux/services/sms';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { NumericFormatCustom } from './NumericFormatCustom';
import { CustomTypography } from '../custom/CustomTypography';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  nickname: state.user.userProfile.nickname,
});

type TAddPhoneBySmsComponent = {
  userId?: string;
  nickname?: string;
};

export const AddPhoneBySmsComponent: FC<TAddPhoneBySmsComponent> = ({ userId, nickname }) => {
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userId), {
    skip: !userId,
  });
  const [addPhoneBySms] = useAddPhoneBySmsMutation();
  const [checkPhoneNumberAvialability] = useLazyCheckPhoneNumberAvialabilityQuery();
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const [codeError, setCodeError] = useState('');
  const navigate = useNavigate();
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [[minute, second], setTime] = useState([1, 30]);
  const [code, setCode] = useState('');
  const [externalAccountExists, setExternalAccountExists] = useState(false);
  const [sendSmsCode, { isLoading: sendSmsCodeLoading }] = useSendSmsCodeMutation();
  const phoneNumberWithCode = '+7' + phoneNumber;

  useEffect(() => {
    setTime([1, 30]);
  }, [isSmsSent]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (
      externalAccounts?.find(
        (account) => account.sub === phoneNumberWithCode && account.type === AccountTypes.SMS,
      )
    )
      return 'Номер телефона уже привязан к вашему аккаунту';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
    if (externalAccountExists) setExternalAccountExists(false);
  };

  const getCodeError = () => {
    if (!code) return 'Укажите код подтверждения';
  };

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const smsInput = () => {
    setIsSmsSent((sent) => !sent); //Нужна какая то другая проверка
    setCodeError('');
    setCode('');
  };

  const handleSmsAction = async () => {
    if (getCodeError()) return;
    try {
      if (phoneNumber && userId) {
        const result = await addPhoneBySms({
          phoneNumber: phoneNumberWithCode,
          userId,
          code,
          rebind: externalAccountExists,
          provider_id,
        });

        if ('error' in result) {
          const { error } = result;
          if ('data' in error && isApiErrorWithMessage(error.data)) {
            return setCodeError(error.data.message);
          }
          return setCodeError('Неверный код подтверждения');
        }

        navigate('/profile');
      }
    } catch (e) {
      console.log('handleSmsAction', e);
    }
  };

  const sendConfirmationCode = async (resend: boolean) => {
    try {
      if (getPhoneNumberError() || !userId) return;

      const response = await checkPhoneNumberAvialability(phoneNumberWithCode).unwrap();

      if (!externalAccountExists && !response.isAvailable) {
        setExternalAccountExists(true);
        return setPhoneNumberError(`Телефон уже используется`);
      }
      await sendSmsCode({
        type: SmsCodeTypes.addPhone,
        providerId: provider_id,
        userId,
        name: nickname,
        phoneNumber: phoneNumberWithCode,
        resend,
        rebind: externalAccountExists,
      })
        .unwrap()
        .catch((e) => {
          if (e?.data?.message === 'code.still.active') {
            return;
          }
          throw e;
        });

      setIsSmsSent(true);

      setTime([1, 30]);
    } catch (e) {
      console.log('sendConfirmationCodeError ', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['change-email']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        ></CustomTypography>
        {!isSmsSent ? (
          <>
            <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
              Номер телефона
            </CustomTypography>
            <TextField
              value={phoneNumber}
              onChange={handleChange}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const phoneNumberErr = getPhoneNumberError();
                if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
              }}
              error={!!phoneNumberError}
              helperText={phoneNumberError && !externalAccountExists ? phoneNumberError : ''}
              fullWidth
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumericFormatCustom as any,
              }}
              variant="standard"
            />
            {externalAccountExists && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ color: '#d32f2f' }} className={'text-14'}>
                  {phoneNumberError}
                </Typography>
              </div>
            )}
            <CustomTypography className={clsx('text-14')} color="grey">
              На этот номер телефона будет отправлен код подтверждения
            </CustomTypography>
          </>
        ) : (
          <>
            <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
              Введите код, отправленный на указанный номер телефона
            </CustomTypography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <CustomTypography className={clsx('text-14')} color="grey">
                  Выслать код повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </CustomTypography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={() => sendConfirmationCode(true)}
                >
                  Выслать код повторно
                </Button>
              )}
              <Button
                onClick={smsInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить номер
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={sendSmsCodeLoading || (externalAccountExists && !isSmsSent)}
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isSmsSent) {
                return handleSmsAction();
              }
              sendConfirmationCode(false);
            }}
          >
            {isSmsSent ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const AddPhoneBySms = connect(mapStateToProps)(AddPhoneBySmsComponent);
