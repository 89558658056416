import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Accordion, AccordionDetails, AccordionSummary, ListItem } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import { TWidgetColors, useUpdateClientMutation } from 'src/redux/services/clients';
import * as yup from 'yup';
import { BACKEND_URL, PROJECT_NAME } from '../../constants';
import { getProviderTitleByType, isObjectEmpty } from '../../helpers';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { setApplicationFormChanged } from '../../redux/appSlice';
import { setNotice } from '../../redux/noticesSlice';
import { TClient, clientApi } from '../../redux/services/client';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  providerApi,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import {
  TProfileField,
  TRule,
  settingsApi,
  useEditSettingsMutation,
  useGetProfileFieldsQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { useGetUserQuery } from '../../redux/services/user';
import { RootState } from '../../redux/store';
import { EGrantTypes } from '../applications/old/CreateApplication';
import { CustomRadioButton } from '../custom/CustomRadioButton';
import { EditEmailTemplates } from '../applications/old/EditEmailTemplates';
import { EditProfileField } from '../applications/old/EditProfileField';
import { EditRuleValidations } from '../applications/old/EditRuleValidations';
import { IEmailTemplate } from '../applications/old/EmailTemplate';
import { ProfileField } from '../applications/old/ProfileField';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { AdvancedSettings } from '../extensions/Settings';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { EditProviders } from '../Providers/EditProviders';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { EditApplicationFooter } from './clientSettings/ClientSettingsFooter';
import { ClientSettingsHeader } from './clientSettings/ClientSettingsHeader';
import styles from './Settings.module.css';

export const editProfileSchema = (rules: TRule[]): yup.AnyObjectSchema => {
  if (!rules) return yup.object();
  return generateValidationSchema(rules.filter((rule) => rule.field_name !== 'password'));
};

export const generateValidationSchema = (rules: TRule[]) => {
  const schemaFields = rules.reduce((schema, field) => {
    if (!field.active) return schema;

    let fieldValidations = yup.string().nullable().notRequired();

    // Применение всех активных правил валидации для поля
    field.validations.forEach((validation) => {
      if (validation.active) {
        fieldValidations = fieldValidations.test({
          name: `${field.field_name}-regex`,
          message: validation.error,
          test: (value) => {
            if (field.required && (value === null || value === undefined || value === '')) {
              return false; // пропускаем проверку, если поле обязательное и пустое
            }
            // Применяем regex только если поле не пустое
            if (value === null || value === undefined || value === '') {
              return true; // пропускаем проверку, если поле пустое
            }
            const result = new RegExp(validation.regex).test(value);
            return result;
          },
        });
      }
    });

    return {
      ...schema,
      [field.field_name]: fieldValidations,
    };
  }, {});

  return yup.object().shape(schemaFields).required();
};

export enum RegistrationPolicyVariants {
  allowed = 'allowed',
  allowed_autoregistration_only = 'allowed_autoregistration_only',
  disabled = 'disabled',
}

export type TSettingsInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  avatar: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  widget_title: string;
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  phone_provider_enabled: boolean;
};

const schema = yup
  .object({
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    registration_policy: yup.string().required('Обязательно поле'),
    allow_everyone_to_create_applications: yup.boolean().required('Обязательно поле'),
    authorize_only_admins: yup.boolean().required('Обязательно поле'),
  })
  .required();

type TSettings = {
  selectedClient: TClient;
  userId: string;
};

export const Settings: FC<TSettings> = ({ selectedClient, userId }) => {
  const dispatch = useDispatch();
  const savedCallback = useRef<() => void>();
  const { openedFromMenu } = useParams<{ openedFromMenu: string }>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const { data: dataSettings } = useGetSettingsQuery();
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: providers = [] } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    action: EGetProviderAction.all,
  });
  const [editSettings, editSettingsResult] = useEditSettingsMutation();
  const [updateClient] = useUpdateClientMutation();

  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [isEmailTemplatesModalOpen, setEmailTemplatesModalOpen] = useState(false);
  const [savePasswordPolicyModalOpen, setSavePasswordPolicyModalOpen] = useState(false);
  const [claims, setClaims] = useState<{ gravatar: string[]; oauth: string[] }>({
    gravatar: [],
    oauth: [],
  });
  // TODO: для phoneProvider принимать и передавать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
  // TODO: для emailProvider тоже принимать только необходимые параметры
  const [phoneProvider, setPhoneProvider] = useState<TMiscProvider | undefined>(undefined);
  const [emailProvider, setEmailProvider] = useState<TMiscProvider | undefined>(undefined);
  const [selectedProfileField, setSelectedProfileField] = useState<TProfileField | undefined>(
    undefined,
  );
  const { refetch: getUser } = useGetUserQuery();
  const emailTemplates: IEmailTemplate[] = dataSettings?.email_templates || [];
  const generalProfileFields = profileFields?.filter((item) => item.type === 'general');
  const customProfileFields = profileFields?.filter((item) => item.type === 'custom');

  const listGeneralProfileFields = generalProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
      />
    );
  });
  const listCustomProfileFields = customProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
        deleted={true}
      />
    );
  });

  useEffect(() => {
    if (dataSettings)
      setClaims({
        oauth: dataSettings.default_public_profile_claims_oauth
          ? dataSettings.default_public_profile_claims_oauth.split(' ')
          : [],
        gravatar: dataSettings.default_public_profile_claims_gravatar
          ? dataSettings.default_public_profile_claims_gravatar.split(' ')
          : [],
      });
  }, [dataSettings]);

  useEffect(() => {
    setValue('default_public_profile_claims_oauth', claims.oauth.join(' '), { shouldDirty: true });
    setValue('default_public_profile_claims_gravatar', claims.gravatar.join(' '), {
      shouldDirty: true,
    });
  }, [claims]);

  useEffect(() => {
    if (editSettingsResult.isSuccess) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Настройки личного кабинета сохранены.`,
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [editSettingsResult]);

  useEffect(() => {
    getUser();
  }, [profileFields]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
      setClaims({
        gravatar: [],
        oauth: [],
      });
    };
  }, []);

  useEffect(() => {
    if (providers.length) {
      if (selectedProfileField?.field === 'phone_number') {
        const phoneProvider = providers.find((provider) => provider.type === ProviderType.PHONE);

        if (phoneProvider && phoneProvider.type === ProviderType.PHONE) {
          setPhoneProvider(phoneProvider);
        } else {
          setPhoneProvider(undefined);
        }
      }

      if (selectedProfileField?.field === 'email') {
        const emailProvider = providers.find((provider) => provider.type === ProviderType.EMAIL);

        if (emailProvider && emailProvider.type === ProviderType.EMAIL) {
          setEmailProvider(emailProvider);
        } else {
          setEmailProvider(undefined);
        }
      }
    }
  }, [selectedProfileField, providers]);

  const methods = useForm<TSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient.redirect_uris.map((uri) => ({ value: uri })) || [{ value: '' }],
      post_logout_redirect_uris: selectedClient.post_logout_redirect_uris.map((uri) => ({
        value: uri,
      })) || [{ value: '' }],
      avatar: selectedClient.avatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    control,
    resetField,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  useEffect(() => {
    if (!dataSettings) return;

    const fieldsToReset = [
      'authorize_only_admins',
      'allow_everyone_to_create_applications',
      'registration_policy',
    ] as const;

    fieldsToReset.forEach((fieldName) => {
      const fieldValue = dataSettings[fieldName];
      if (fieldValue !== undefined) {
        resetField(fieldName, { defaultValue: fieldValue });
      }
    });
  }, [dataSettings]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TSettingsInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }

    if (Object.keys(errors).length) return;
    const payload = Object.entries(data).map(([key, value]) => {
      if (key === 'post_logout_redirect_uris' || key === 'redirect_uris') {
        return [
          key,
          data[key].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []),
        ];
      }

      return [key, value];
    });

    const isKeyOfGeneralSettings = (
      key:
        | string
        | number
        | boolean
        | string[]
        | File
        | {
            name: string;
            value: string;
          }[]
        | TWidgetColors
        | null,
    ) => {
      return (
        typeof key === 'string' &&
        [
          'registration_policy',
          'allow_everyone_to_create_applications',
          'authorize_only_admins',
          'default_public_profile_claims_oauth',
          'default_public_profile_claims_gravatar',
          'phone_provider_enabled',
        ].includes(key)
      );
    };

    await Promise.all([
      updateClient({
        id: selectedClient.client_id,
        data: {
          ...Object.fromEntries(payload.filter(([key]) => !isKeyOfGeneralSettings(key))),
          grant_types: [
            EGrantTypes.authorization_code,
            EGrantTypes.refresh_token,
            EGrantTypes.device_flow,
          ],
          registration_access_token: selectedClient.registration_access_token?.jti,
          client_id: selectedClient.client_id,
          redirect_uris: selectedClient.redirect_uris,
          post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        },
      }),
      editSettings(Object.fromEntries(payload.filter(([key]) => isKeyOfGeneralSettings(key)))),
    ]);

    dispatch(providerApi.util.invalidateTags(['Providers']));

    dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <div className={'wrapper-scroll'}>
        <div className={'content'}>
          {!!openedFromMenu && (
            <div className={styles['header-text']}>
              <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
                Настройки личного кабинета
              </CustomTypography>
              <CustomTypography
                style={{ marginBottom: 14 }}
                className={clsx('text-14')}
                color="grey"
              >
                Добавляйте способы входа, управляйте доступом к личному кабинету и настраивайте
                внешний вид виджета для авторизации пользователей.
              </CustomTypography>
            </div>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ClientSettingsHeader
                coverSrc={coverSrc}
                setCoverSrc={setCoverSrc}
                avatarSrc={avatarSrc}
                client={selectedClient}
                setAvatarSrc={setAvatarSrc}
                openedFromMenu={openedFromMenu}
              />
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomMuiIcon
                        className={styles['accorion-summary-icon']}
                        Icon={KeyboardArrowDownOutlinedIcon}
                      />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Способы входа
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails className={styles['accordion-details']}>
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => {
                      setProviderModalOpen(true);
                    }}
                  >
                    Настроить
                  </Button>
                  <div className={styles.providers}>
                    {providers
                      .filter((p) => p.is_active)
                      .map((provider) => {
                        return (
                          <div key={provider.id} className={styles.provider}>
                            <div
                              style={{
                                backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                              }}
                              className={styles['provider-icon-wrapper']}
                            >
                              {!provider.avatar &&
                                (provider.type === 'PHONE' ? (
                                  <PhoneAndroidOutlinedIcon />
                                ) : (
                                  <IdIcon />
                                ))}
                            </div>
                            <div className={styles['provider-name-wrapper']}>
                              <CustomTypography
                                className={clsx('text-14', styles['provider-name'])}
                              >
                                {provider.name}
                              </CustomTypography>
                              <CustomTypography className={clsx('text-12')} color="grey">
                                {getProviderTitleByType(provider.type)}
                              </CustomTypography>
                            </div>
                            {selectedClient.required_providers_ids.includes(
                              String(provider.id),
                            ) && (
                              <IconWithTooltip
                                iconType="starFilled"
                                description="Для входа в приложение не требуется
                                 наличие способа входа в профиле пользователя"
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Настройка профиля пользователя
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails className={styles['accordion-details']}>
                  <CustomTypography
                    className={clsx('text-14', styles['input-subtitle'])}
                    color="grey"
                  >
                    Настройте параметры полей профиля и добавьте идентификаторы, которые должны быть
                    у пользователя в профиле
                  </CustomTypography>
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Парольная политика
                  </CustomTypography>
                  <ListItem
                    className={styles['password-item']}
                    onClick={() => setSavePasswordPolicyModalOpen(true)}
                  >
                    <div className={styles['password-content']}>
                      <div className={styles['password-head']}>
                        <CustomTypography className={clsx('text-14', styles['password-title'])}>
                          Пароль
                        </CustomTypography>
                        <CustomTypography color="grey" className="text-12">
                          password
                        </CustomTypography>
                      </div>
                    </div>
                  </ListItem>
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Основная информация
                  </CustomTypography>
                  {listGeneralProfileFields}
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Дополнительная информация
                  </CustomTypography>
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setProfileModalOpen(true)}
                  >
                    Добавить
                  </Button>
                  {listCustomProfileFields}
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Шаблоны писем
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setEmailTemplatesModalOpen(true)}
                  >
                    Настроить
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Настройки доступа
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <div className={styles['input-wrapper']}>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'authorize_only_admins'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <div>
                                  <CustomTypography className={clsx('text-14')}>
                                    Ограниченный доступ
                                  </CustomTypography>
                                  <CustomTypography className={clsx('text-12')} color="grey">
                                    Вход в личный кабинет доступен только для пользователей с
                                    правами “Администратор” {PROJECT_NAME}
                                  </CustomTypography>
                                </div>
                              }
                              value={true}
                              checked={!!value}
                              onChange={() => onChange(true)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <div>
                                  <CustomTypography className={clsx('text-14')}>
                                    Неограниченный доступ
                                  </CustomTypography>
                                  <CustomTypography className={clsx('text-12')} color="grey">
                                    Вход в личный кабинет не ограничен
                                  </CustomTypography>
                                </div>
                              }
                              value={false}
                              checked={!value}
                              onChange={() => onChange(false)}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles['input-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['input-title'])}>
                      Запрет регистрации
                    </CustomTypography>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'registration_policy'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация запрещена
                                </CustomTypography>
                              }
                              checked={value === RegistrationPolicyVariants.disabled}
                              onClick={() => onChange(RegistrationPolicyVariants.disabled)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация разрешена
                                </CustomTypography>
                              }
                              checked={value === RegistrationPolicyVariants.allowed}
                              onClick={() => onChange(RegistrationPolicyVariants.allowed)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Разрешена только авторегистрация
                                </CustomTypography>
                              }
                              checked={
                                value === RegistrationPolicyVariants.allowed_autoregistration_only
                              }
                              onClick={() =>
                                onChange(RegistrationPolicyVariants.allowed_autoregistration_only)
                              }
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles['input-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['input-title'])}>
                      Регистрация пользователей с правами “Администратора”
                    </CustomTypography>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'allow_everyone_to_create_applications'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация с правами администратора
                                </CustomTypography>
                              }
                              checked={!!value}
                              onChange={() => onChange(true)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация без прав администратора
                                </CustomTypography>
                              }
                              checked={!value}
                              onChange={() => onChange(false)}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <AdvancedSettings />
              <EditApplicationFooter
                coverSrc={coverSrc}
                applicationFormChanged={applicationFormChanged}
                avatarSrc={avatarSrc}
                savedCallback={savedCallback}
                setSaveModalOpen={setSaveModalOpen}
              />
            </form>
          </FormProvider>
          <EditProviders isOpen={providerModalOpen} onClose={() => setProviderModalOpen(false)} />
          <EditEmailTemplates
            close={() => setEmailTemplatesModalOpen(false)}
            isOpen={isEmailTemplatesModalOpen}
            templates={emailTemplates}
          />
          <EditProfileField
            close={() => setProfileModalOpen(false)}
            clearSelectedProfileField={() => setSelectedProfileField(undefined)}
            isOpen={profileModalOpen}
            selectedProfile={selectedProfileField}
            claims={claims}
            phoneProvider={phoneProvider}
            emailProvider={emailProvider}
          />
          <EditRuleValidations
            close={() => setSavePasswordPolicyModalOpen(false)}
            field_name={'password'}
            isOpen={savePasswordPolicyModalOpen}
          />
          <ConfirmationModal
            title="Сохранение изменений"
            mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
            actionButtonText="Продолжить"
            isOpen={saveModalOpen}
            onAction={() => {
              savedCallback.current?.();
              dispatch(setApplicationFormChanged(false));
              setSaveModalOpen(false);
            }}
            onClose={closeSaveModal}
          />
        </div>
      </div>
    </>
  );
};
