import { BACKEND_URL } from '../constants';
import { getAccessToken } from '../service/auth';
const baseUrl = BACKEND_URL + '/api/kloud/v1/';

export const callKloud = async ({
  providerId,
  phoneNumber,
  clientId,
}: {
  providerId: string;
  phoneNumber: string;
  clientId: string;
}): Promise<boolean | undefined> => {
  try {
    const url = BACKEND_URL + '/api/v1/verification/code';
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        type: 'KLOUD',
        phone_number: '+7' + phoneNumber,
        provider_id: providerId,
        client_id: clientId,
      }),
    });

    return response.ok;
  } catch (e) {
    console.log('callKloud error: ', e);
  }
};

export const checkKloudCode = async ({
  providerId,
  phoneNumber,
  clientId,
  code,
}: {
  providerId: string;
  phoneNumber: string;
  clientId: string;
  code: string;
}): Promise<boolean | undefined> => {
  try {
    const url =
      baseUrl +
      'check' +
      '?phone_number=' +
      encodeURIComponent(phoneNumber) +
      '&client_id=' +
      encodeURIComponent(clientId) +
      '&provider_id=' +
      providerId +
      '&code=' +
      encodeURIComponent(code);

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    return response.ok;
  } catch (e) {
    console.log('checkKloudCode error: ', e);
  }
};

export const checkKloudAvialability = async (
  phoneNumber: string,
  providerId: string,
): Promise<{ isAvailable: boolean; nickname: string } | undefined> => {
  try {
    const accessToken = await getAccessToken();

    const url =
      BACKEND_URL +
      `/api/v1/verification/check?type=KLOUD&phone_number=${encodeURIComponent(
        phoneNumber,
      )}&provider_id=${providerId}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (response?.ok) return response.json();
  } catch (e) {
    console.log('checkKloudAvialability error: ', e);
  }
};
