import { Avatar, Box } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { CreateSidePanel } from 'src/components/sidePanel/CreateSidePanel';
import { CLIENT_ID } from 'src/constants';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  TOauthProvider,
  useDeleteProviderMutation,
  useGetProvidersQuery,
} from 'src/redux/services/provider';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { ChooseProvider } from './ChooseProvider';
import styles from './EditProviders.module.css';
import { CustomTypography } from 'src/components/custom/CustomTypography';
import { getImageURL, getProviderTitleByType } from 'src/helpers';
import { IconWithTooltip } from 'src/components/shared/IconWithTooltip';
import { EditLdapProvider } from './edit/EditLdapProvider';
import { Edit1CProvider } from './edit/Edit1CProvider';
import { EditIdmProvider } from './edit/EditIdmProvider';
import { ConfirmationModal } from 'src/components/modal/ConfirmationModal';
import { addProvidersTypes, addProviderType } from '../Settings';

type TEditTrustedProviders = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditTrustedProviders: FC<TEditTrustedProviders> = ({ isOpen, onClose }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const [providers, setProviders] = useState<(TOauthProvider | TMiscProvider)[]>([]);
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEditLdapFormOpen, setIsEditLdapFormOpen] = useState(false);
  const [isEdit1CFormOpen, setIsEdit1CFormOpen] = useState(false);
  const [isEditIdmFormOpen, setIsEditIdmFormOpen] = useState(false);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    action: EGetProviderAction.all,
    types: [addProviderType.LDAP, addProviderType.ALDPRO, addProviderType._1C, addProviderType.IDM],
  });
  const [deleteProvider] = useDeleteProviderMutation();

  useEffect(() => {
    if (appProviders) {
      setProviders(appProviders);
    }
  }, [appProviders]);

  useEffect(() => {
    if (!isEditFormOpen) setProviderToEdit(null);
  }, [isEditFormOpen]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const createProviderSettings = async (provider: TOauthProvider | TMiscProvider) => {
    setProviderToEdit(provider);

    switch (provider.type) {
      case addProviderType.LDAP:
      case addProviderType.ALDPRO:
        return setIsEditLdapFormOpen(true);
      case addProviderType._1C:
        return setIsEdit1CFormOpen(true);
      case addProviderType.IDM:
        return setIsEditIdmFormOpen(true);
      default:
        setIsEditFormOpen(true);
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    await navigator.clipboard.writeText(JSON.stringify(provider));
  };

  return (
    <>
      <CreateSidePanel
        handleClosePanel={onClose}
        isOpenPanel={isOpen}
        title={'Настроить провайдеры'}
        description={''}
        createAction={() => setIsCreateFormOpen(true)}
        isNoBackdrop={false}
      >
        <div className={styles.wrapper}>
          <div style={{ overflow: 'auto', paddingBottom: '40px', height: 'calc(100vh - 166px)' }}>
            {!providers.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ManageSearchOutlinedIcon />
                <CustomTypography
                  style={{ marginBottom: 8 }}
                  className={clsx('text-17')}
                  color="grey"
                >
                  Список пуст
                </CustomTypography>
                <CustomTypography
                  className={clsx('text-14')}
                  style={{ marginBottom: 16, color: '#B6BAC6' }}
                >
                  Добавьте провайдеры
                </CustomTypography>
                <Button
                  onClick={() => setIsCreateFormOpen(true)}
                  variant="custom"
                  className={clsx('text-14')}
                  color="secondary"
                  startIcon={<AddOutlinedIcon />}
                />
              </div>
            )}
            {providers.slice().map((provider) => {
              return (
                <div
                  className={clsx(styles.provider, {
                    [styles['checked-provider']]: provider.is_active,
                  })}
                  key={provider.id}
                >
                  <Box className={styles['provider-icon-wrapper']}>
                    {provider.avatar ? <Avatar src={getImageURL(provider.avatar)} /> : <IdIcon />}
                  </Box>
                  <div className={styles['provider-name-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                      {provider.name}
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')} color="grey">
                      {getProviderTitleByType(provider.type, addProvidersTypes)}
                    </CustomTypography>
                  </div>
                  <div className={styles['provider-buttons']}>
                    <IconWithTooltip
                      dataAttribute={'delete-provider-button'}
                      iconType="delete"
                      onClick={() => {
                        setProviderToDelete(provider);
                        setDeleteModalOpen(true);
                      }}
                      disabled={
                        !!fetchingProviderIds.find((id) => id === provider.id) || providersFetching
                      }
                    />
                    <IconWithTooltip
                      dataAttribute={'edit-provider-button'}
                      iconType="edit"
                      onClick={() => createProviderSettings(provider)}
                    />
                    <IconWithTooltip
                      dataAttribute={'copy-provider-button'}
                      iconType="copy"
                      placement="bottom-end"
                      onClick={() => handleCopyProvider(provider)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CreateSidePanel>
      <ChooseProvider isOpen={isCreateFormOpen} onClose={() => setIsCreateFormOpen(false)} />
      <EditLdapProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditLdapFormOpen}
        onClose={() => {
          setIsEditLdapFormOpen(false);
        }}
      />
      <Edit1CProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEdit1CFormOpen}
        onClose={() => {
          setIsEdit1CFormOpen(false);
        }}
      />
      <EditIdmProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditIdmFormOpen}
        onClose={() => {
          setIsEditIdmFormOpen(false);
        }}
      />

      <ConfirmationModal
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDeleteProvider}
        disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
        title="Удалить способ входа"
        mainMessage={[
          'Способ входа будет удален. Если способ входа используется в приложении, пользователи не смогут войти в него, используя данный способ входа.',
        ]}
      />
    </>
  );
};
