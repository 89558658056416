import Button from '@mui/material/Button';
import React, { ChangeEvent, FC, ReactElement, useEffect } from 'react';
import styles from './UploadAndDisplayImage.module.css';
import clsx from 'clsx';
import { getImageURL, toBase64 } from '../../../helpers';

type TUploadAndDisplayImageProps = {
  setAvatarError: (error: string) => void;
  setAvatarValue: (value: File | null) => void;
  clearAvatarError: () => void;
  setImgSrc: (value: string | null) => void;
  maxImageSize?: number;
  componentName?: string;
  DefaultIcon?: ReactElement;
  overRideImageSrc?: File | string | null;
  pathToAvatar?: string | null;
  setAvatarLink?: (value: string) => void;
  imgSrc?: string | null;
  disabled?: boolean;
  onAvailableAvatarsButtonClick?: () => void;
};

export const UploadAndDisplayImage: FC<TUploadAndDisplayImageProps> = ({
  setAvatarError,
  setAvatarValue,
  clearAvatarError,
  componentName,
  DefaultIcon,
  overRideImageSrc,
  pathToAvatar,
  setAvatarLink,
  imgSrc,
  setImgSrc,
  disabled,
  maxImageSize = 1,
  onAvailableAvatarsButtonClick,
}) => {
  useEffect(() => {
    if (typeof pathToAvatar !== 'string') pathToAvatar = null;
    setImgSrc(pathToAvatar);
  }, [pathToAvatar]);

  useEffect(() => {
    if (overRideImageSrc) {
      const setImgSrcAsync = async () => {
        if (overRideImageSrc instanceof Blob) {
          setImgSrc(await toBase64(overRideImageSrc));
        } else {
          setImgSrc(overRideImageSrc);
        }
      };

      setImgSrcAsync();
    }
  }, [overRideImageSrc]);

  return (
    <div className={styles.container}>
      <div className={styles['flex-container']}>
        <div
          style={{
            backgroundImage: `url(${imgSrc?.startsWith('data:') ? imgSrc : getImageURL(imgSrc)})`,
          }}
          className={clsx(styles['app-icon'], {
            [styles['avatar']]: DefaultIcon?.props.title === 'Profile avatar',
            [styles['gray-avatar']]: DefaultIcon?.props.title === 'Profile avatar' && !imgSrc,
          })}
        >
          {!imgSrc && DefaultIcon}
        </div>
        {imgSrc && !disabled && (
          <Button
            onClick={() => {
              setImgSrc(null);
              setAvatarValue(null);
              clearAvatarError();
              setAvatarLink?.('');
            }}
            className={styles['delete-button']}
            variant="custom"
            color="secondary"
          >
            Удалить
          </Button>
        )}
        <Button
          className={styles['load-button']}
          variant="custom"
          component="label"
          color="secondary"
          disabled={disabled}
        >
          Загрузить
          <input
            id={componentName}
            type="file"
            name="myImage"
            hidden
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files?.[0];

              if (file) {
                const src = await toBase64(file);
                if (
                  !['jpeg', 'png', 'jpg', 'svg+xml'].find(
                    (imageType) => `image/${imageType}` === file.type,
                  )
                )
                  return setAvatarError('Недопустимый формат изображения');

                if (file.size / 1024 / 1024 > maxImageSize)
                  return setAvatarError(
                    `Размер изображения не должен превышать ${maxImageSize} МБ`,
                  );

                setImgSrc(src);
                setAvatarValue(file);
                event.target.value = '';
                clearAvatarError();
                setAvatarLink?.('');
              }
            }}
          />
        </Button>
        {onAvailableAvatarsButtonClick && (
          <Button variant="custom" color="secondary" onClick={onAvailableAvatarsButtonClick}>
            Доступные
          </Button>
        )}
      </div>
    </div>
  );
};
