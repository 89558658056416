import Drawer from '@mui/material/Drawer';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './PublicProfile.module.css';
import { getPublicProfile, getVCard, IPublicProfile } from '../../requests/user';
import { formatDate, getImageURL } from '../../helpers';
import { ExternalAccount } from './ExternalAccount';
import { TExternalAccount } from '../../redux/services/user';
import { useDispatch } from 'react-redux';
import { setNotice } from '../../redux/noticesSlice';
import { TCustomFields } from '../../redux/userSlice';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { CustomTypography } from '../custom/CustomTypography';
import { useTheme } from '@mui/material';

/**
 * Всплывающее окно с публичным профилем пользователя
 * @param param0
 * @returns
 */
export const PublicProfile = ({
  userEmail,
  isOpen,
  close,
}: {
  userEmail: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [loadingVCard, setLoadingVCard] = useState(false);
  const theme = useTheme();
  const [publicProfile, setPublicProfile] = useState<IPublicProfile | undefined>(undefined);
  const linesExternalAccounts: (JSX.Element | null | undefined)[] = [];
  const linesInfo: (JSX.Element | null | undefined)[] = [];

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const fetchPublicProfile = async () => {
      const profile = await getPublicProfile(userEmail);
      setPublicProfile(profile);
    };
    fetchPublicProfile();
  }, [isOpen]);

  if (!publicProfile) {
    return null;
  }

  // Генерация всех строчек профиля и external accounts
  Object.keys(publicProfile).forEach((key) => {
    // Пропускаем ключи, которые не нужно отображать, так как они отображаются отдельно
    if (key === 'email' || key === 'picture') {
      return;
    }

    // Обработка внешних аккаунтов
    if (key === 'ExternalAccount') {
      const externalAccounts = publicProfile[key] as unknown as Array<TExternalAccount>;
      if (externalAccounts) {
        externalAccounts.forEach((externalAccount) => {
          linesExternalAccounts.push(
            <ExternalAccount
              public_accounts_claims_gravatar={undefined}
              public_accounts_claims_oauth={undefined}
              userProfile={{ email: userEmail, id: '' }}
              account={externalAccount}
              withoutButtons={true}
            />,
          );
        });
      }
      return;
    }

    if (key === 'custom_fields') {
      const cf = publicProfile[key] as TCustomFields;
      Object.keys(cf).forEach((keyCF) => {
        const valueCF = cf[keyCF];
        if (valueCF) {
          linesInfo.push(
            <CustomTypography key={keyCF} className={clsx('text-14', styles['info-item-value'])}>
              {valueCF}
            </CustomTypography>,
          );
        }
      });
      return;
    }

    // Обработка остальных данных
    let value = publicProfile[key];
    // Проверяем, что значение существует и не пустая строка
    if (value) {
      if (key === 'birthdate' && typeof value === 'string') {
        value = formatDate(value);
      }

      linesInfo.push(
        <CustomTypography key={key} className={clsx('text-14', styles['info-item-value'])}>
          {value}
        </CustomTypography>,
      );
    }

    return;
  });

  // Получение vCard файла
  const handleVCardDownload = async () => {
    try {
      setLoadingVCard(true);
      const vCardBlob = await getVCard(userEmail); // Убедитесь, что getVCard возвращает Blob
      if (vCardBlob) {
        const url = window.URL.createObjectURL(vCardBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf'; // Имя файла, которое будет предложено пользователю
        document.body.appendChild(a); // Добавляем элемент в документ для корректной работы в Firefox
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a); // Удаляем элемент после скачивания
      }
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: 'Ошибка при скачивании vCard',
          timestamp: new Date().toString(),
        }),
      );
    } finally {
      setLoadingVCard(false);
    }
  };

  return (
    <>
      <Drawer
        onClose={() => close()}
        open={isOpen}
        anchor="right"
        variant="temporary"
        classes={{ paper: styles['drawer-paper'] }}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Публичный профиль
            </CustomTypography>
            <div>
              <IconWithTooltip
                iconType="download"
                onClick={() => void handleVCardDownload()}
                disabled={loadingVCard}
              />
              <IconWithTooltip
                iconType="closeWindow"
                onClick={close}
                buttonSx={{
                  '&:hover path': {
                    stroke: theme.palette.custom.main,
                  },
                }}
                disabled={loadingVCard}
              />
            </div>
          </div>
          <div className={styles.content}>
            {publicProfile.picture ? (
              <div
                style={{
                  backgroundImage: `url(${getImageURL(publicProfile.picture)})`,
                }}
                className={styles['user-icon-wrapper']}
              />
            ) : null}
            <CustomTypography color="secondary" className={clsx('text-14', styles.email)}>
              {publicProfile.email}
            </CustomTypography>

            {linesInfo.length > 0 && (
              <div className={styles.panel}>
                <CustomTypography className={clsx('header-3', 'font-golos', styles['panel-title'])}>
                  Основная информация
                </CustomTypography>
                {linesInfo}
              </div>
            )}

            {linesExternalAccounts.length > 0 && (
              <div className={styles.panel}>
                <CustomTypography className={clsx('header-3', 'font-golos', styles['panel-title'])}>
                  Внешние аккаунты
                </CustomTypography>
                {linesExternalAccounts}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
