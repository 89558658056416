export enum Roles {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  USER = 'USER',
  TRUSTED_USER = 'TRUSTED_USER',
}

/**
 * Порядок сортировки
 */
export enum Order {
  /**
   * По возрастанию
   */
  ASC = 'asc',
  /**
   * По убыванию
   */
  DESC = 'desc',
}

export enum screenDevices {
  Desktop = 1024,
  Tablet = 768,
  miniTablet = 600,
  Mobile = 440,
}

export enum cardHeight {
  Desktop = 112,
  Indent = 12
}

export enum appTabs {
  clients = '/applications',
  users = '/applications/users',
  settings = '/applications/edit',
  eventLog = '/applications/event-log',
}

export enum filterMethods {
  all = 'Все',
  public = 'Публичные',
  private = 'Для приложения',
}
