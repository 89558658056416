import React, { useEffect, useState } from 'react';
import { CustomTypography } from '../../custom/CustomTypography';
import { Button, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from '../../custom/CustomSelect';
import styles from './LicenseSelect.module.css';
import clsx from 'clsx';
import { ConfirmationModal } from 'src/components/modal/ConfirmationModal';
import { activateLicense, getLicenses, TLicenseInfo } from './requests';

interface TLicenseSelect {
  flag: string;
  currentLicense?: number;
}

export const LicenseSelect = ({ flag, currentLicense }: TLicenseSelect) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [inputLicense, setInputLicense] = useState<string>('');
  const [licenses, setLicenses] = useState<TLicenseInfo[]>([]);
  const { control } = useFormContext();

  useEffect(() => {
    loadLicense();
  }, []);

  const loadLicense = async () => {
    const licenseList = await getLicenses();
    if (currentLicense) {
      setInputLicense(currentLicense.toString());
    }
    setLicenses(licenseList.filter((license) => license.flags.includes(flag)));
  };

  const handleSaveLicense = async () => {
    await activateLicense(inputLicense, 'Описание лицензии');
    loadLicense();
    setInputLicense('');
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className={clsx(styles['license-title-wrap'], styles['input-title'])}>
        <CustomTypography className={'text-14'}>Лицензия</CustomTypography>
        <Button variant="custom2" onClick={() => setIsModalOpen(true)}>
          Добавить
        </Button>
      </div>
      <Controller
        control={control}
        name="license_id"
        render={({ field }) => (
          <CustomSelect
            data-id="license-select"
            onChange={(e) => {
              field.onChange(e.target.value === '(нет лицензии)' ? '' : e.target.value);
            }}
            className={styles.select}
            value={field.value === '' ? '(нет лицензии)' : field.value}
          >
            <MenuItem
              className="custom-select"
              value={'(нет лицензии)'}
              data-id="empty-license-item"
            >
              (нет лицензии)
            </MenuItem>
            {licenses.map((license, index) => (
              <MenuItem
                className="custom-select"
                key={license.value}
                value={license.id}
                data-id={`license-item-${index}`}
              >
                {license.value}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        В рамках одной лицензии вы можете использовать один и тот же Адрес сервера
      </CustomTypography>

      {isModalOpen && (
        <ConfirmationModal
          title="Введите значение"
          children={
            <TextField
              value={inputLicense}
              onChange={(e) => setInputLicense(e.target.value)}
              fullWidth
              variant="standard"
              style={{ marginBottom: 20 }}
            />
          }
          actionButtonText="Добавить"
          isOpen={isModalOpen}
          onAction={handleSaveLicense}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};
