import { ICustomStyles } from './components/custom/Theme';

export const DOMAIN = window?.env?.['DOMAIN'];
export const NODE_ENV = window?.env?.['NODE_ENV'] || 'development';
export const DASHBOARD_URL = window?.env?.['DASHBOARD_URL'] || DOMAIN;
export const BACKEND_URL = window?.env?.['BACKEND_URL'] || DOMAIN;
export const MANUAL_URL = window?.env?.['MANUAL_URL'] || 'https://docs.trusted.plus';
export const SMS_PROVIDER_URL = window?.env?.['SMS_PROVIDER_URL'] || 'https://smsc.ru';
export const GRAVATAR_URL = window?.env?.['GRAVATAR_URL'] || 'https://www.gravatar.com/avatar';
export const DATA_PROCESSING_POLICY_URL =
  window?.env?.['DATA_PROCESSING_POLICY_URL'] ||
  'https://github.com/DigtLab-QA/policy/raw/master/policy_id_trusted_ru.pdf';
export const PROJECT_NAME = window?.env?.['PROJECT_NAME'] || 'ID.Trusted.Net';
// для корректного отображения логотипа в email не рекомендуется использовать изображение в формате .SVG
export const LOGO_URL = window?.env?.['LOGO'] || BACKEND_URL + '/public/images/logo.png';

//auth
export const CLIENT_ID = window?.env?.['CLIENT_ID'];

export const ACCESS_TOKEN_TTL = parseInt(window?.env?.['ACCESS_TOKEN_TTL'] || '1800000', 10); //30min
export const REFRESH_TOKEN_TTL = parseInt(window?.env?.['REFRESH_TOKEN_TTL'] || '86400000 ', 10); //24h
export const TRUSTED_VERSION = window?.env?.['TRUSTED_VERSION'] || 'v3.2.21';
export const COPYRIGHT =
  window?.env?.['COPYRIGHT'] || `© ${new Date().getFullYear()}, Цифровые технологии`;

export const CUSTOM_STYLES: ICustomStyles = window?.env?.['CUSTOM_STYLES']
  ? JSON.parse(window.env['CUSTOM_STYLES'])
  : {};

export const LOADING_TEXT = 'Загрузка...';
