import React from 'react';
import { ChooseMethods } from './trustedProviders/ChooseMethods';

interface IAddTemplatesProps {
  onClose: () => void;
}

export const AddTemplates: React.FC<IAddTemplatesProps> = ({
  onClose,
}) => {
  return (
    <>
      <ChooseMethods onClose={onClose} />
    </>
  );
};