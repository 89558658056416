import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { responseListItems } from 'src/redux/types';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './listElements.module.css';
import { SearchForm } from './SearchForm';

interface IListElementsProps<T, L, P> {
  heightListItem: number;
  padding: number;
  query: (offset: number, search: string) => L;
  getItems: LazyQueryTrigger<QueryDefinition<L, any, any, responseListItems<T[]>, any>>;
  RowElement: React.ComponentType<any>;
  rowElementProps: Partial<P>;
  searchFormChildren?: React.ReactNode;
  isFullHeight?: boolean;
}

const ListElementsComponent = <T, L, P>({
  heightListItem,
  padding,
  query,
  getItems,
  RowElement,
  rowElementProps,
  searchFormChildren,
  isFullHeight,
}: IListElementsProps<T, L, P>) => {
  const [items, setItems] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const infiniteLoaderRef = useRef(null);
  const SEARCH_HEIGHT = 50;
  const HEADER = 200;
  const MIN_ITEMS_COUNT = 4;
  const fullHeightCount = (heightListItem + padding + padding) * totalCount;
  const fullHeight = totalCount < MIN_ITEMS_COUNT ? `calc(100vh - ${HEADER}px)` :`${fullHeightCount}px`;

  useEffect(() => {
    loadItems(true, 0);
  }, []);

  const isItemLoaded = (index: number) => !!items[index];

  const loadMoreItems = async (startIndex: number) => {
    if (items.length > 0) {
      await loadItems(false, startIndex);
    }
  };

  const loadItems = async (setTotal: boolean, startIndex: number) => {
    const queryParams = query(startIndex, searchString);
    const { data } = await getItems(queryParams);
    if (!data?.totalCount) {
      setEmptyState(true);
    }

    if (data) {
      setItems((prevItems) => [...prevItems, ...data.items]);
      setTotal && setTotalCount(data.totalCount);
    }
  };

  const updateItems = (items: T[], totalCount: number) => {
    setTotalCount(totalCount);
    setItems(items);
    setEmptyState(totalCount === 0);
  };

  const innerElementType = forwardRef<
    HTMLDivElement,
    { style: CSSProperties; children?: React.ReactNode }
  >(({ style, children }, ref) => (
    <div
      ref={ref}
      style={{ ...style, height: `${parseFloat(style.height as string) + padding}px` }}
    >
      {children}
    </div>
  ));
  innerElementType.displayName = 'InnerElementType';

  return (
    <>
      <div style={{ height: isFullHeight ? fullHeight : '100%' }}>
        <SearchForm<T, L>
          query={query}
          getItems={getItems}
          setSearchString={setSearchString}
          updateItems={updateItems}
        >
          {searchFormChildren}
        </SearchForm>
        {emptyState ? (
          <Box className={styles.emptyState}>
            <SearchIcon className={styles.emptyIcon} />
            <CustomTypography className={clsx('text-17-regular', 'font-golos')} color="grey">
              По вашему запросу ничего не найдено
            </CustomTypography>
          </Box>
        ) : (
          <div data-id="list-items" className={styles.listContainer}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={totalCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer disableWidth>
                  {({ height }: { height: number }) => (
                    <List
                      height={isFullHeight ? fullHeightCount : (height - SEARCH_HEIGHT)}
                      itemSize={heightListItem + padding}
                      width={'100%'}
                      innerElementType={innerElementType}
                      className={styles.list}
                      itemCount={totalCount}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      itemData={items}
                    >
                      {({ data, index, style }) => (
                        <RowElement
                          items={data}
                          index={index}
                          style={style}
                          updateItems={updateItems}
                          {...rowElementProps}
                        />
                      )}
                    </List>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Компонент ListElementsComponent отображает список элементов с бесконечной прокруткой.
 * @component
 *
 * @template T - Тип данных, отображаемых в списке.
 * @template L - Тип параметров запроса.
 * @template P - Тип дополнительных свойств, передаваемых в компонент RowElement.
 *
 * @param heightListItem - Высота каждого элемента списка в пикселях.
 * @param padding - Отступы между элементами списка в пикселях.
 * @param query - Функция для создания параметров запроса с пагинацией.
 * @param getItems - Асинхронная функция для получения элементов списка.
 * @param RowElement - Компонент для рендеринга отдельного элемента списка.
 * @param rowElementProps - Дополнительные свойства, передаваемые в компонент RowElement.
 * @param searchFormChildren - Дочерние элементы для компонента формы поиска.
 *
 * @returns {JSX.Element} Компонент списка c бесконечной прокруткой.
 */
export const ListItems = ListElementsComponent;
