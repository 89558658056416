import styles from './TopTabsProfile.module.css';
import React, { FC, SyntheticEvent, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { RootState } from '../../redux/rootReducer';
import { setProfileTab } from '../../redux/appSlice';
import { useTheme } from '@mui/material';

type TApplicationsProps = {
  userId?: string;
  profileTab: string;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  profileTab: state.app.profileTab,
});

const TopTabsProfileComponent: FC<TApplicationsProps> = ({
  userId,
  profileTab 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const theme = useTheme();

  useEffect(() => {
    if (location.endsWith('/profile')) dispatch(setProfileTab('/profile'));
    if (location.startsWith(`/profile/scopes`)) dispatch(setProfileTab(`/profile/scopes`));
    if (location.startsWith(`/profile/activity`)) dispatch(setProfileTab(`/profile/activity`));
    if (location.startsWith(`/profile/catalog`)) dispatch(setProfileTab(`/profile/catalog`));
  }, [location]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    dispatch(setProfileTab(newValue));
    navigate(newValue);
  };

  return (
    <Box className={styles.applications} sx={{ height: 54, display: 'flex', alignItems: 'center' }}>
      <Tabs
        className={styles.tabs}
        sx={{
          [`& .${styles.tab}`]: {
            color: theme.palette.custom.mainGrey,
          },
        }}
        TabIndicatorProps={{ className: styles['tab-indicator'] }}
        value={profileTab}
        onChange={handleChange}
        classes={{ flexContainer: styles.tabsContainer }}
      >
        <Tab
          icon={<AccountCircleOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          classes={{ selected: styles['tab-selected'] }}
          label="Профиль"
          id="0"
          value={'/profile'}
          onClick={() => navigate('/profile')}
        />
        <Tab
          icon={<GppGoodOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          classes={{ selected: styles['tab-selected'] }}
          label="Разрешения"
          id="1"
          value={'/profile/scopes'}
          onClick={() => navigate('/profile/scopes', { state: { userId } })}
        />
        <Tab
          icon={<TocOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          classes={{ selected: styles['tab-selected'] }}
          label="Журнал"
          id="2"
          value={'/profile/activity'}
          onClick={() => navigate('/profile/activity', { state: { userId } })}
        />
        <Tab
          icon={<BookmarksOutlinedIcon />}
          iconPosition="start"
          className={styles.tab}
          classes={{ selected: styles['tab-selected'] }}
          label="Каталог"
          id="3"
          value={'/profile/catalog'}
          onClick={() => navigate('/profile/catalog')}
        />
      </Tabs>
    </Box>
  );
};

export const TopTabsProfile = connect(mapStateToProps)(TopTabsProfileComponent);
