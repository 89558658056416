import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from '@mui/material';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { CustomMuiIcon } from '../../custom/CustomMuiIcon';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from '../../settings/Settings.module.css';
import { SentryPanel } from './SentryPanel';

const SentryComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Sentry
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails
          style={{ alignItems: 'flex-start' }}
          className={styles['accordion-details']}
        >
          <Button
            variant="custom2"
            className={styles['add-button']}
            onClick={() => setIsModalOpen(true)}
          >
            Настроить
          </Button>
        </AccordionDetails>
      </Accordion>
      <SentryPanel isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export const Sentry = memo(SentryComponent);
