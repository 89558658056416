import { yupResolver } from '@hookform/resolvers/yup';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  logoutUriSchema,
  redirectUriSchema,
  requestUriSchema,
} from 'src/components/applications/old/CreateApplication';
import { CustomMuiIcon } from 'src/components/custom/CustomMuiIcon';
import { useUpdateClientMutation } from 'src/redux/services/clients';
import * as yup from 'yup';
import { BACKEND_URL, CLIENT_ID } from '../../../constants';
import { getProviderTitleByType, isObjectEmpty, isUrl } from '../../../helpers';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { setApplicationFormChanged } from '../../../redux/appSlice';
import {
  TApplication,
  TClient,
  clientApi,
  useGetApplicationByIdQuery,
} from '../../../redux/services/client';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  TOauthProvider,
  useDeactivateProvidersMutation,
  useGetProvidersQuery,
} from '../../../redux/services/provider';
import { ProviderScope } from '../../../redux/services/settings';
import { RootState } from '../../../redux/store';
import { CustomTypography } from '../../custom/CustomTypography';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import { EditProviders } from '../../Providers/EditProviders';
import { IconWithTooltip } from '../../shared/IconWithTooltip';
import { IconWrapper } from '../../shared/IconWrapper';
import styles from './ClientSettings.module.css';
import { EditApplicationFooter } from './ClientSettingsFooter';
import { ClientSettingsHeader } from './ClientSettingsHeader';

export type TWidgetColors = { button_color: string; link_color: string; font_color: string };

export type TClientSettingsInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  request_uris: {
    name: string;
    value: string;
  }[];
  response_types: string[];
  grant_types: string[];
  // #371 refresh_token_ttl: string;
  // #371 access_token_ttl: string;
  avatar: File | string | null;
  cover: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  hide_widget_header: boolean;
  hide_widget_footer: boolean;
  widget_title: string;
  client_id: string;
  client_secret: string;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
  is_visible: boolean;
};

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255),
    domain: yup
      .string()
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirect_uris: yup.array().of(redirectUriSchema).required(),
    post_logout_redirect_uris: yup.array().of(logoutUriSchema).required(),
    request_uris: yup.array().of(requestUriSchema).required(),
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    client_id: yup
      .string()
      .required('Обязательное поле')
      .matches(/^[^\n ]*$/, {
        message: 'Идентификатор не может содержать пробелы',
      })
      .matches(/^[A-Za-z0-9_-]+$/, {
        message:
          'Может содержать латинские буквы (a-z), цифры (0-9), дефис (-) и нижнее подчёркивание (_)',
      }),
    client_secret: yup.string().required('Обязательное поле'),
  })
  .required();

type TClientSettings = {
  selectedClient: TClient;
  userId: string;
};

export const ClientSettings: FC<TClientSettings> = ({ selectedClient, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(selectedClient.avatar);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);

  const savedCallback = useRef<() => void>();
  const settingsFormChanged = useSelector((state: RootState) => state.app.applicationFormChanged);
  const methods = useForm<TClientSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient?.redirect_uris?.map((uri) => ({ value: uri })) || [
        { value: '' },
      ],
      post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris.length
        ? selectedClient.post_logout_redirect_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      request_uris: selectedClient?.request_uris.length
        ? selectedClient.request_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      response_types: selectedClient?.response_types,
      grant_types: selectedClient?.grant_types,
      require_auth_time: selectedClient?.require_auth_time,
      require_signed_request_object: selectedClient?.require_signed_request_object,
      avatar: selectedClient?.avatar,
      cover: selectedClient?.cover,
      client_id: selectedClient.client_id,
      client_secret: selectedClient.client_secret,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  const [updateClient, { isLoading: updateClientLoading }] = useUpdateClientMutation();

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (settingsFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TClientSettingsInputs> = async (data) => {
    try {
      if (data.redirect_uris.every((uri) => !uri.value)) {
        setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
        return;
      }
      if (Object.keys(errors).length) return;
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TApplication['client'], 'avatar' | 'cover'> & {
              avatar: File | null;
              cover: File | null;
            }
          >,
          field,
        ) => {
          if (
            field === 'post_logout_redirect_uris' ||
            field === 'redirect_uris' ||
            field === 'request_uris'
          ) {
            acc[field] = data[field].reduce((dataAcc: string[], uri) => {
              if (uri.value) dataAcc.push(uri.value);
              return dataAcc;
            }, []);
          } else if (field === 'avatar' || field === 'cover') {
            if (typeof data[field] !== 'string') acc[field] = data[field] as File;
          } else if (field === 'widget_colors') acc.widget_colors = data.widget_colors;
          else if (
            field === 'show_avatar_in_widget' ||
            field === 'hide_widget_footer' ||
            field === 'hide_widget_header' ||
            field === 'require_auth_time' ||
            field === 'is_visible' ||
            field === 'require_signed_request_object'
          )
            acc[field] = data[field];
          else if (field === 'response_types' || field === 'grant_types') acc[field] = data[field];
          else acc[field] = data[field];
          return acc;
        },
        {},
      );

      await updateClient({
        id: selectedClient?.client_id,
        data: {
          ...payload,
          registration_access_token: selectedClient?.registration_access_token?.jti,
          grant_types: payload?.grant_types ?? selectedClient?.grant_types,
          redirect_uris: payload?.redirect_uris ?? selectedClient?.redirect_uris,
          post_logout_redirect_uris:
            payload?.post_logout_redirect_uris ?? selectedClient?.post_logout_redirect_uris,
          request_uris: payload?.request_uris ?? selectedClient?.request_uris,
          require_signed_request_object:
            payload?.require_signed_request_object ?? selectedClient?.require_signed_request_object,
          id_token_signed_response_alg:
            payload?.id_token_signed_response_alg ?? selectedClient?.id_token_signed_response_alg,
          response_types: payload?.response_types ?? selectedClient?.response_types,
          introspection_endpoint_auth_method:
            payload?.introspection_endpoint_auth_method ??
            selectedClient?.introspection_endpoint_auth_method,
          require_auth_time: payload?.require_auth_time ?? selectedClient?.require_auth_time,
          revocation_endpoint_auth_method:
            payload?.revocation_endpoint_auth_method ??
            selectedClient?.revocation_endpoint_auth_method,
          token_endpoint_auth_method:
            payload?.token_endpoint_auth_method ?? selectedClient?.token_endpoint_auth_method,
          subject_type: payload?.subject_type ?? selectedClient?.subject_type,
        },
      }).unwrap();
      dispatch(
        clientApi.endpoints.getApplicationById.initiate(
          { client_id: selectedClient.client_id, user_id: userId },
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      );
      navigate('/applications');
    } catch (e) {
      console.log('err', e);
    }
  };

  const { data: providers = [], isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
    action: EGetProviderAction.change,
  });
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const { isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: selectedClient.client_id || '' },
    {
      skip: !userId || !selectedClient.client_id,
    },
  );

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateClientLoading || getApplicationFetching || !selectedClient) return;
    await updateClient({
      id: selectedClient.client_id,
      data: {
        required_providers_ids: selectedClient.required_providers_ids.includes(String(provider.id))
          ? selectedClient.required_providers_ids.filter((id) => id !== String(provider.id))
          : [...selectedClient.required_providers_ids, String(provider.id)],
        grant_types: selectedClient.grant_types,
        registration_access_token: selectedClient.registration_access_token?.jti,
        client_id: selectedClient.client_id,
        redirect_uris: selectedClient.redirect_uris,
        post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        require_signed_request_object: selectedClient.require_signed_request_object,
        request_uris: selectedClient.request_uris,
        id_token_signed_response_alg: selectedClient.id_token_signed_response_alg,
        response_types: selectedClient.response_types,
        introspection_endpoint_auth_method: selectedClient.introspection_endpoint_auth_method,
        require_auth_time: selectedClient.require_auth_time,
        revocation_endpoint_auth_method: selectedClient.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient.token_endpoint_auth_method,
        subject_type: selectedClient.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  //TODO проверить работу этой функции
  const handleProviderClick = (
    provider: TOauthProvider | TMiscProvider,
    providerScope: ProviderScope,
  ) => {
    if (providerScope === ProviderScope.login) {
      return;
    }

    if (
      provider.type !== ProviderType.CREDENTIALS &&
      provider.type !== ProviderType.EMAIL &&
      provider.type !== ProviderType.PHONE &&
      provider.client_id === selectedClient.client_id
    ) {
      setProviderModalOpen(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ClientSettingsHeader
              coverSrc={coverSrc}
              setCoverSrc={setCoverSrc}
              avatarSrc={avatarSrc}
              client={selectedClient}
              setAvatarSrc={setAvatarSrc}
            />
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={
                  <IconButton size="large" disableRipple={false}>
                    <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
                  </IconButton>
                }
              >
                <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                  Способы входа
                </CustomTypography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  onClick={() => {
                    setProviderModalOpen(true);
                  }}
                >
                  Настроить
                </Button>
                <div className={styles.providers}>
                  {providersLoading &&
                    [null, null].map((_, index) => (
                      <div key={index} className={styles.provider}>
                        <IconWrapper>
                          <Skeleton width={20} height={30} />
                        </IconWrapper>
                        <div>
                          <CustomTypography className={clsx('text-14')}>
                            <Skeleton />
                          </CustomTypography>
                          <CustomTypography className={clsx('text-12')} color="grey">
                            OAuth 2
                          </CustomTypography>
                        </div>
                        <IconButton className={styles['icon-button-wrapper']}>
                          <CloseOutlinedIcon className={styles['icon-button']} />
                        </IconButton>
                      </div>
                    ))}
                  {providers
                    .filter((provider) => provider.is_active)
                    .map((provider) => {
                      return (
                        <div
                          key={provider.id}
                          className={styles.provider}
                          onClick={() => handleProviderClick(provider, ProviderScope.login)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                            }}
                            className={styles['provider-icon-wrapper']}
                          >
                            {!provider.avatar && <IdIcon />}
                          </div>
                          <div className={styles['provider-name-wrapper']}>
                            <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                              {provider.name}
                            </CustomTypography>
                            <CustomTypography className={clsx('text-12')} color="grey">
                              {getProviderTitleByType(provider.type)}
                            </CustomTypography>
                          </div>
                          <IconWithTooltip
                            iconType="starFilled"
                            description="Для входа в приложение не требуется
                                 наличие способа входа в профиле пользователя"
                            onClick={() => handleChangeRequiredProviders(provider)}
                          />
                          {provider.is_active &&
                            !(
                              selectedClient.client_id === CLIENT_ID &&
                              provider.type === ProviderType.CREDENTIALS
                            ) && (
                              <IconButton
                                onClick={async (e) => {
                                  if (!userId) return;
                                  e.stopPropagation();
                                  await deactivateProvider({
                                    body: {
                                      providers: [parseInt(provider.id, 10)],
                                    },
                                    client_id: selectedClient.client_id,
                                  });
                                  dispatch(
                                    clientApi.endpoints.getApplicationById.initiate(
                                      { client_id: selectedClient.client_id, user_id: userId },
                                      {
                                        subscribe: false,
                                        forceRefetch: true,
                                      },
                                    ),
                                  );
                                }}
                                className={styles['icon-button-wrapper']}
                              >
                                <CloseOutlinedIcon className={styles['icon-button']} />
                              </IconButton>
                            )}
                        </div>
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
            <EditApplicationFooter
              coverSrc={coverSrc}
              applicationFormChanged={settingsFormChanged}
              avatarSrc={avatarSrc}
              savedCallback={savedCallback}
              setSaveModalOpen={setSaveModalOpen}
            />
          </form>
        </FormProvider>
        <EditProviders onClose={() => setProviderModalOpen(false)} isOpen={providerModalOpen} />

        <ConfirmationModal
          title="Сохранение изменений"
          mainMessage={['Изменения не сохранены. Продолжить без сохранения?']}
          actionButtonText="Продолжить"
          isOpen={saveModalOpen}
          onAction={() => {
            savedCallback.current?.();
            dispatch(setApplicationFormChanged(false));
            setSaveModalOpen(false);
          }}
          onClose={closeSaveModal}
        />
      </div>
    </div>
  );
};
