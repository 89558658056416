import React, { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ProviderType,
  TEditProvider,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../../../redux/services/provider';

import { isObjectEmpty } from '../../../helpers';
import { ProviderFooter } from '../ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { ProviderSidePanel } from 'src/components/shared/ProviderSidePanel';

export type EditEthereumProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

export const EditEthereumProvider: FC<TEditProvider> = ({ isOpen, onClose, providerToEdit }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isClipboardModalOpen, setIsClipboardModalOpen] = useState(false);
  const [overrideImage, setOverrideImage] = useState<string | null>(null);
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (updateResult.isSuccess) onClose();
  }, [updateResult]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const methods = useForm<EditEthereumProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    reset,
  } = methods;

  const watchDescription = watch('description');

  const closeModals = () => {
    setIsSaveModalOpen(false);
    setIsClipboardModalOpen(false);
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) onClose();
    else setIsSaveModalOpen(true);
  };

  const setFields = async (provider?: Partial<TMiscProvider & { provider_id: string }>) => {
    try {
      let selectedCopy:
        | Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { type, avatar, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;

      if (type !== ProviderType.ETHEREUM) {
        setIsClipboardModalOpen(true);
      } else {
        if (avatar && typeof avatar !== 'string') {
          setOverrideImage(URL.createObjectURL(avatar));
          setValue('avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<EditEthereumProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditEthereumProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public'
          ) {
            acc[field] = data[field];
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: ProviderType.ETHEREUM,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <ProviderSidePanel
      isOpen={isOpen}
      onClose={handleClose}
      pasteFields={setFields}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      isOpenSaveModal={isSaveModalOpen}
      isClipboardModalOpen={isClipboardModalOpen}
      onCloseModal={closeModals}
      watchDescription={watchDescription}
      avatar={providerToEdit?.avatar || ''}
      overrideImage={overrideImage}
      text={'avatar' as keyof EditEthereumProviderInputs}
      loading={updateResult.isLoading}
    >
      <ProviderFooter type={ProviderType.ETHEREUM} clientId={clientId} />
    </ProviderSidePanel>
  );
};
