import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, debounce, InputBase } from '@mui/material';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { responseListItems } from 'src/redux/types';
import styles from './SearchForm.module.css';

interface SearchFormProps<T, L> {
  setSearchString: Dispatch<SetStateAction<string>>;
  query: (offset: number, search: string) => L;
  getItems: LazyQueryTrigger<QueryDefinition<L, any, any, responseListItems<T[]>, any>>;
  updateItems: (items: T[], totalCount: number) => void;
  children?: React.ReactNode;
}

const SearchFormComponent = <T, L>({
  setSearchString,
  query,
  getItems,
  updateItems,
  children,
}: SearchFormProps<T, L>) => {
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const onSearch = async (value: string) => {
    setSearchString(value);
    const queryParams = query(0, value);
    const { data } = await getItems(queryParams);
    
    if (data) {
      updateItems(data.items, data.totalCount);
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 200), []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearchDebounce(value);
  };

  return (
    <div data-id="search-form" className={styles.searchWrapper}>
      <Box className={clsx(styles.search, active && styles.searchActive)}>
        <SearchIcon className={styles.searchIcon} />
        <InputBase
          className={styles.searchInputWrapper}
          onBlur={() => setActive(false)}
          onFocus={() => setActive(true)}
          onChange={handleSearch}
          value={searchValue}
          classes={{ input: clsx('text-14', styles.searchInput) }}
          placeholder="Поиск…"
          inputProps={{ 'aria-label': 'search' }}
        />
        {searchValue && 
          <CloseOutlinedIcon
            data-id="close-search-button"
            className={styles.closeIcon}
            onClick={() => {
              setSearchValue('');
              onSearch('');
            }}
          />
        }
      </Box>
      {children}
    </div>
  );
};

/**
 * Поле поиска с иконкой поиска и кнопкой очистки.
 * @component
 * @param setSearchString - Функция для управления состоянием строки поиска (useState).
 * @param query - Функция для создания параметров запроса.
 * @param getItems - Функция для получения элементов.
 * @param updateItems - Функция для обновления элементов.
 * @param children - Дочерние для отображения рядом со строкой поиска.
 * 
 * @returns {JSX.Element} Компонент строки поиска и дополнительных элементов управления.
 */
export const SearchForm = (SearchFormComponent);