import { fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { responseListItems } from './types';
import { BACKEND_URL } from 'src/constants';
import { getAccessToken } from 'src/service/auth';

/**
 * Преобразует ответ в формат, содержащий информацию о пагинации и данные для вывода списка.
 * @template T - Тип данных, список получаемых объектов.
 * @param response - Ответ, содержащий данные для вывода списка.
 * @param meta - Метаданные, заголовки ответа.
 */
export const parseResponse = <T>(response: T, meta: FetchBaseQueryMeta): responseListItems<T> => {
  const totalCount = parseInt(meta?.response?.headers.get('X-Total-Count') || '0', 10);
  const perPage = parseInt(meta?.response?.headers.get('x-per-page') || '0', 10);
  const currentOffset = parseInt(meta?.response?.headers.get('x-current-offset') || '0', 10);
  const nextOffset = parseInt(meta?.response?.headers.get('x-next-offset') || '0', 10);

  return {
    items: response,
    totalCount,
    perPage,
    currentOffset,
    nextOffset,
  };
};

/**
 * Создает объект запроса для выполнения HTTP-запроса к API.
 * @template T - Тип query параметров запроса.
 * @param endPoint - Конечная точка API, к которой будет отправлен запрос.
 * @param method - HTTP-метод запроса ('GET', 'POST', 'DELETE').
 * @param query - Параметры запроса, которые будут добавлены.
 */
export const createFetchArgs = <T>(endPoint: string, method: string, query?: T) => {
  return {
    url: `${endPoint}`,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    params: query,
  };
};

/**
 * Создает объект запроса для выполнения HTTP-запроса к API c телом запроса.
 * @template T - Тип параметров для тела запроса.
 * @param endPoint - Конечная точка API, к которой будет отправлен запрос.
 * @param method - HTTP-метод запроса ('GET', 'POST', 'DELETE').
 * @param body - Параметры, которые будут добавлены в тело запроса.
 */
export const createFetchArgsWithBody = <T>(endPoint: string, method: string, body: T) => {
  return {
    url: `${endPoint}`,
    method,
    body,
  };
};

/**
 * Создает базовый запрос для выполнения HTTP-запросов к API с добавлением заголовка авторизации.
 * @param point - Конечная точка API, к которой будет отправлен запрос.
 */
export const createBaseQuery = (point: string) =>
  fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/${point}`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  });
