import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from '@mui/material';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { CustomMuiIcon } from '../../custom/CustomMuiIcon';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from '../../settings/Settings.module.css';
import { EditTrustedProviders } from './EditProviders';
import { EGetProviderAction, useGetProvidersQuery } from 'src/redux/services/provider';
import { BACKEND_URL } from 'src/constants';
import { getProviderTitleByType } from 'src/helpers';
import { useParams } from 'react-router-dom-v5-compat';
import { ReactComponent as IdIcon } from '../../../icons/Id.svg';
import { addProvidersTypes, addProviderType } from '../Settings';

const TrustedProvidersComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const { data: providers = [] } = useGetProvidersQuery({
    client_id: clientId,
    action: EGetProviderAction.all,
    types: [addProviderType.LDAP, addProviderType.ALDPRO, addProviderType._1C, addProviderType.IDM],
  });

  return (
    <>
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Доверенные провайдеры
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails className={styles['accordion-details']}>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="custom2"
            className={styles['add-button']}
          >
            Настроить
          </Button>
          <div className={styles.providers}>
            {providers.map((provider) => {
              return (
                <div key={provider.id} className={styles.provider}>
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.avatar && <IdIcon />}
                  </div>
                  <div className={styles['provider-name-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                      {provider.name}
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')} color="grey">
                      {getProviderTitleByType(provider.type, addProvidersTypes)}
                    </CustomTypography>
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
      <EditTrustedProviders onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
    </>
  );
};

export const TrustedProviders = memo(TrustedProvidersComponent);
