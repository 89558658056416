import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './EditProviders.module.css';

import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import {
  EGetProviderAction,
  ProviderType,
  TMiscProvider,
  TOauthProvider,
  useActivateProvidersMutation,
  useDeactivateProvidersMutation,
  useDeleteProviderMutation,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import { ChooseProvider } from './ChooseProvider';

import { Box, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import { useUpdateClientMutation } from 'src/redux/services/clients';
import { BACKEND_URL, CLIENT_ID } from '../../constants';
import { getProviderTitleByType } from '../../helpers';
import { RootState } from '../../redux/rootReducer';
import { clientApi, useGetApplicationByIdQuery } from '../../redux/services/client';
import { CustomCheckbox } from '../custom/CustomCheckbox';
import { CustomTypography } from '../custom/CustomTypography';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { CreateSidePanel } from '../sidePanel/CreateSidePanel';
import { EditEthereumProvider } from './edit/EditEthereumProvider';
import { EditKloudProvider } from './edit/EditKloudProvider';
import { EditProvider } from './edit/EditProvider';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import FilterComponent from './FilterComponent';
import { EditSmsProvider } from './edit/EditSmsProvider';
import { addMethodsType, addProvidersTypes } from '../extensions/Settings';

type TEditProviders = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditProviders: FC<TEditProviders> = ({ isOpen, onClose }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const { clientId = '' } = useParams<{ clientId: string }>();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const { data: client, isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId },
    {
      skip: !userId || !clientId,
    },
  );
  const theme = useTheme();
  const selectedClient = client?.client;
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEditEthereumFormOpen, setIsEditEthereumFormOpen] = useState(false);
  const [isEditSmsFormOpen, setIsEditSmsFormOpen] = useState(false);
  const [isEditKloudFormOpen, setIsEditKloudFormOpen] = useState(false);
  const [providers, setProviders] = useState<(TOauthProvider | TMiscProvider)[]>([]);
  const anchorPopover = useRef<HTMLButtonElement>(null);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    action: EGetProviderAction.all,
  });
  const allProviderTypes:string[] = [...Object.values(ProviderType), ...Object.values(addMethodsType)];

  const dispatch = useDispatch();
  const [deleteProvider] = useDeleteProviderMutation();
  const [activateProvider] = useActivateProvidersMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [updateClient, { isLoading: updateClientLoading }] = useUpdateClientMutation();

  useEffect(() => {
    !!appProviders?.length && setProviders(appProviders);
  }, [appProviders]);

  useEffect(() => {
    if (!isEditFormOpen) setProviderToEdit(null);
  }, [isEditFormOpen]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const createProviderSettings = async (provider: TOauthProvider | TMiscProvider) => {
    setProviderToEdit(provider);

    switch (provider.type) {
      case ProviderType.ETHEREUM:
        return setIsEditEthereumFormOpen(true);
      case ProviderType.SMS:
        return setIsEditSmsFormOpen(true);
      case ProviderType.KLOUD:
        return setIsEditKloudFormOpen(true);
      default:
        setIsEditFormOpen(true);
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    await navigator.clipboard.writeText(JSON.stringify(provider));
  };

  const handleActivateProvider = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId) return;
    setFetchingProviderIds((ids) => [...ids, provider.id]);

    if (!provider.is_active) {
      await activateProvider({
        body: {
          providers: [parseInt(provider.id, 10)],
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: [parseInt(provider.id, 10)],
        },
        client_id: clientId,
      });
    }

    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: clientId, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );

    setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== provider.id));
  };

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateClientLoading || getApplicationFetching || !selectedClient) return;
    await updateClient({
      id: selectedClient.client_id,
      data: {
        required_providers_ids: selectedClient.required_providers_ids.includes(String(provider.id))
          ? selectedClient.required_providers_ids.filter((id) => id !== String(provider.id))
          : [...selectedClient.required_providers_ids, String(provider.id)],
        grant_types: selectedClient?.grant_types,
        registration_access_token: selectedClient?.registration_access_token?.jti,
        client_id: selectedClient?.client_id,
        redirect_uris: selectedClient?.redirect_uris,
        post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris,
        require_signed_request_object: selectedClient?.require_signed_request_object,
        request_uris: selectedClient?.request_uris,
        id_token_signed_response_alg: selectedClient?.id_token_signed_response_alg,
        response_types: selectedClient?.response_types,
        introspection_endpoint_auth_method: selectedClient?.introspection_endpoint_auth_method,
        require_auth_time: selectedClient?.require_auth_time,
        revocation_endpoint_auth_method: selectedClient?.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient?.token_endpoint_auth_method,
        subject_type: selectedClient?.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  return (
    <>
      <CreateSidePanel
        handleClosePanel={onClose}
        isOpenPanel={isOpen}
        title={'Настроить способы входа'}
        description={''}
        createAction={() => setIsCreateFormOpen(true)}
        isNoBackdrop={false}
      >
        <div className={styles.wrapper}>
          <FilterComponent
            anchorPopover={anchorPopover}
            items={appProviders}
            clientId={clientId}
            updateProviders={(value: (TOauthProvider | TMiscProvider)[]) => setProviders(value)}
          />
          <div style={{ overflow: 'auto', paddingBottom: '40px', height: 'calc(100vh - 166px)' }}>
            {providers.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CustomMuiIcon
                  className={styles.emptyIcon}
                  Icon={SearchOutlinedIcon}
                  color="grey"
                ></CustomMuiIcon>
                <CustomTypography
                  style={{ marginBottom: 8 }}
                  className={clsx('text-17')}
                  color="grey"
                >
                  Список пуст
                </CustomTypography>
                <CustomTypography
                  className={clsx('text-14')}
                  style={{ marginBottom: 16, color: '#B6BAC6' }}
                >
                  Нет доступных способов входа для выбранного фильтра
                </CustomTypography>
                <Button
                  onClick={() => setIsCreateFormOpen(true)}
                  variant="custom"
                  className={clsx('text-14')}
                  color="secondary"
                  startIcon={<PostAddOutlinedIcon />}
                >
                  Создать
                </Button>
              </div>
            ) : (
              providers?.map((provider) => {
                const required = selectedClient?.required_providers_ids.includes(
                  String(provider.id),
                );
                return (
                  <div
                    className={clsx(styles.provider, {
                      [styles['checked-provider']]: provider.is_active,
                    })}
                    key={provider.id}
                  >
                    <CustomCheckbox
                      onClick={() => {
                        handleActivateProvider(provider);
                      }}
                      checked={provider.is_active}
                      className={styles['provider-checkbox']}
                      disabled={
                        !!fetchingProviderIds.find((id) => id === provider.id) ||
                        (clientId === CLIENT_ID && provider.type === ProviderType.CREDENTIALS)
                      }
                    />
                    <Box
                      sx={{
                        '& path:nth-child(2),& path:nth-child(3)': {
                          stroke: theme.palette.custom.main,
                          fill: 'none',
                        },
                      }}
                      style={{
                        backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                      }}
                      className={styles['provider-icon-wrapper']}
                    >
                      {!provider.avatar &&
                        (provider.type === 'PHONE' ? <PhoneAndroidOutlinedIcon /> : <IdIcon />)}
                    </Box>
                    <div className={styles['provider-name-wrapper']}>
                      <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                        {provider.name}
                      </CustomTypography>
                      <CustomTypography className={clsx('text-12')} color="grey">
                        {getProviderTitleByType(provider.type, addProvidersTypes)}
                      </CustomTypography>
                    </div>
                    {allProviderTypes.includes(provider.type) && (
                      <div className={styles['provider-buttons']}>
                        {getProviderTitleByType(provider.type, addProvidersTypes) === ProviderType.OAUTH && (
                          <IconWithTooltip
                            iconType={required ? 'starFilled' : 'star'}
                            onClick={() => handleChangeRequiredProviders(provider)}
                            description={`Для входа в приложение ${
                              !required && 'не'
                            } требуется  наличие способа входа в профиле пользователя`}
                          />
                        )}
                        <IconWithTooltip
                          dataAttribute={'delete-provider-button'}
                          iconType="delete"
                          onClick={() => {
                            setProviderToDelete(provider);
                            setDeleteModalOpen(true);
                          }}
                          disabled={
                            !!fetchingProviderIds.find((id) => id === provider.id) ||
                            providersFetching
                          }
                        />
                        <IconWithTooltip
                          dataAttribute={'edit-provider-button'}
                          iconType="edit"
                          onClick={() => createProviderSettings(provider)}
                        />
                        <IconWithTooltip
                          dataAttribute={'copy-provider-button'}
                          iconType="copy"
                          onClick={() => handleCopyProvider(provider)}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </CreateSidePanel>
      <ChooseProvider isOpen={isCreateFormOpen} onClose={() => setIsCreateFormOpen(false)} />
      <EditProvider
        providerToEdit={providerToEdit as TOauthProvider}
        isOpen={isEditFormOpen}
        onClose={() => {
          setIsEditFormOpen(false);
        }}
      />
      <EditEthereumProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditEthereumFormOpen}
        onClose={() => {
          setIsEditEthereumFormOpen(false);
        }}
      />
      <EditSmsProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditSmsFormOpen}
        onClose={() => {
          setIsEditSmsFormOpen(false);
        }}
      />
      <EditKloudProvider
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditKloudFormOpen}
        onClose={() => {
          setIsEditKloudFormOpen(false);
        }}
      />

      <ConfirmationModal
        isOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        onAction={handleDeleteProvider}
        disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
        title="Удалить способ входа"
        mainMessage={[
          'Способ входа будет удален. Если способ входа используется в приложении, пользователи не смогут войти в него, используя данный способ входа.',
        ]}
      />
    </>
  );
};
