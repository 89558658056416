import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styles from './SidePanel.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IProviderSettingsSidePanel {
  children: React.ReactNode;
  handleClosePanel: () => void;
  isOpenPanel: boolean;
  title: string;
  setPasteFields: () => void;
  isNoBackdrop: boolean;
}
export const ProviderSettingsSidePanel = ({
  children,
  handleClosePanel,
  isOpenPanel,
  title,
  setPasteFields,
  isNoBackdrop,
}: IProviderSettingsSidePanel) => {
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: clsx(isNoBackdrop && 'none') } },
      }}
      onClose={handleClosePanel}
      open={isOpenPanel}
      anchor={isMobile ? 'bottom' : 'right'}
      variant="temporary"
    >
      <div className={styles.header}>
        <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
          {title}
        </CustomTypography>
        <IconWithTooltip iconType="pasteSettings" onClick={setPasteFields} />
        <IconButton className={styles['close-button']} onClick={handleClosePanel}>
          <CloseOutlinedIcon />
        </IconButton>
      </div>
      {children}
    </Drawer>
  );
};
