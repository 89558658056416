import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export const phoneApi = createApi({
  reducerPath: 'phoneApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/phone/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkPhoneExists: builder.query<{ isAvailable: boolean }, string>({
      query: (phoneNumber) =>
        `${BACKEND_URL}/api/v1/verification/check?type=PHONE&phone_number=${encodeURIComponent(
          phoneNumber,
        )}`,
    }),

    callPhone: builder.mutation<
      { success: boolean; code_type: string; code_length?: number; message?: string },
      string
    >({
      query: (phoneNumber) => ({
        url: BACKEND_URL + '/api/v1/verification/code',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'PHONE',
          phone_number: '+7' + phoneNumber,
        }),
      }),
    }),
  }),
});

export const { useCallPhoneMutation, useLazyCheckPhoneExistsQuery } = phoneApi;
