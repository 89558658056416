import React, { FC } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ReactComponent as One } from '../../icons/One.svg';
import { ReactComponent as OneFilled } from '../../icons/OneFilled.svg';
import { ReactComponent as Pen } from '../../icons/Pen.svg';
import { ReactComponent as PenFilled } from '../../icons/PenFilled.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as DownloadIcon } from '../../icons/Download.svg';
import { ReactComponent as PasteProviderIcon } from '../../icons/PasteProvider.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, SvgIconProps, SxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';

type IconType =
  | 'edit'
  | 'delete'
  | 'id'
  | 'star'
  | 'starFilled'
  | 'copy'
  | 'one'
  | 'oneFilled'
  | 'pen'
  | 'penFilled'
  | 'download'
  | 'closeWindow'
  | 'pasteSettings'
  | 'info';
interface IconMathTitleType {
  type: IconType;
  icon: React.ReactElement;
  title: string;
  sx?: SxProps<Theme>;
}

interface IIconWithTooltipProps {
  dataAttribute?: string;
  iconType?: IconType;
  onClick?: () => void;
  customTitle?: string;
  customIcon?: React.ReactElement;
  muiIcon?: React.ElementType<SvgIconProps>;
  muiIconStyles?: string;
  customStyleButton?: string;
  placement?: TooltipProps['placement'];
  description?: string;
  disabled?: boolean;
  buttonSx?: SxProps<Theme>;
}

export const IconWithTooltipComponent: FC<IIconWithTooltipProps> = ({
  dataAttribute,
  iconType,
  onClick,
  customTitle,
  customIcon,
  muiIcon,
  muiIconStyles,
  customStyleButton,
  placement,
  buttonSx,
  description,
  disabled,
}) => {
  const theme = useTheme();

  const hoverColor = {
    '&:hover path': {
      color: theme.palette.custom.main,
    },
    '&:hover circle': {
      color: theme.palette.custom.main,
    },
  };

  const iconMatchTitle: IconMathTitleType[] = [
    {
      type: 'edit',
      icon: <EditOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: 'Редактировать',
      sx: hoverColor,
    },
    {
      type: 'delete',
      icon: <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: 'Удалить',
      sx: hoverColor,
    },
    {
      type: 'id',
      icon: <IdIcon />,
      title: 'Используется как логин',
    },
    {
      type: 'star',
      icon: <StarBorderOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: 'Сделать обязательным',
    },
    {
      type: 'starFilled',
      icon: <StarOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: 'Сделать необязательным',
    },
    {
      type: 'copy',
      icon: <ContentCopyOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: 'Копировать настройки',
      sx: hoverColor,
    },
    {
      type: 'one',
      icon: <One />,
      title: 'Не уникальное',
    },
    {
      type: 'oneFilled',
      icon: <OneFilled />,
      title: 'Уникальное',
    },
    {
      type: 'pen',
      icon: <Pen />,
      title: 'Не редактируемое',
    },
    {
      type: 'penFilled',
      icon: <PenFilled />,
      title: 'Редактируемое',
    },
    {
      type: 'download',
      icon: <DownloadIcon />,
      title: 'Экспортировать vCard',
    },
    {
      type: 'closeWindow',
      icon: <CloseIcon />,
      title: 'Закрыть окно',
    },
    {
      type: 'pasteSettings',
      icon: <PasteProviderIcon />,
      title: 'Вставить настройки',
      sx: {
        marginLeft: 'auto',
        '&:hover path': {
          fill: theme.palette.custom.main,
        },
      },
    },
    {
      type: 'info',
      icon: <InfoOutlinedIcon sx={{ color: theme.palette.custom.mainGrey }} />,
      title: '-',
      sx: {
        marginLeft: 'auto',
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  ];

  const iconParams = iconMatchTitle?.find((iconItem) => iconItem.type === iconType);

  return (
    <Tooltip
      placement={placement}
      arrow
      title={
        <>
          <CustomTypography className={description ? 'text-17' : 'text-12'} color="background">
            {customTitle ?? iconParams?.title}
          </CustomTypography>
          {description && (
            <CustomTypography className="text-12" color="background">
              {description}
            </CustomTypography>
          )}
        </>
      }
    >
      <Button
        data-id={dataAttribute || `${iconParams?.type}-button`}
        disabled={disabled}
        sx={buttonSx || iconParams?.sx || {}}
        className={customStyleButton}
        onClick={(event) => {
          event.stopPropagation();
          onClick && onClick();
        }}
      >
        {customIcon ? (
          customIcon
        ) : muiIcon ? (
          <CustomMuiIcon Icon={muiIcon} className={muiIconStyles} color="grey" />
        ) : (
          iconParams?.icon
        )}
      </Button>
    </Tooltip>
  );
};

/**
 * Компонент IconWithTooltip отображает иконку внутри кнопки с всплывающей подсказкой.
 * Есть как заготовленные так и можно использовать кастомные варианты. Среди кастомных разделение на готовые mui или свои иконки
 *
 * @component
 * @param action - Функция, вызываемая при взаимодействии с кнопкой при клике.
 * @param iconType - Определяет тип иконки, отображаемой внутри кнопки (например, 'info', 'edit', 'error').
 * @param {'top' | 'bottom' | 'left' | 'right'} placement - Положение всплывающей подсказки относительно кнопки.
 * @param customTitle - Пользовательский заголовок для отображения внутри подсказки. Если не задан, используется.
 * @param description - Описание, отображаемое под заголовком внутри подсказки.
 * @param disabled - Флаг, определяющий, должна ли кнопка быть отключена.
 * @param buttonSx - Дополнительные стили для компонента Button.
 * @param customIcon - Пользовательская иконка, заменяющая стандартную иконку Material UI (React.Node).
 * @param customStyleButton - Кастомные классы для компонента Button.
 * @param muiIcon - Иконка Material UI, которая будет отображена внутри кнопки (React.ComponentType).
 * @param muiIconStyles - Кастомные классы для компонента CustomMuiIcon.
 *
 * @returns {JSX.Element} Компонент кнопки с иконкой и всплывающей подсказкой.
 */
export const IconWithTooltip = IconWithTooltipComponent;
