import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { cardHeight, Order, screenDevices } from 'src/enums';
import { RootState } from 'src/redux/rootReducer';
import { TClient, useLazyGetClientsQuery } from 'src/redux/services/clients';
import { IQuerySortParams } from 'src/redux/types';
import { ListItems } from '../shared/listElements';
import { ClientCard, IClientCardProps } from './ClientCard';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
});

interface IClientsListProps {
  screenSize: number;
}

const ClientsListComponent: FC<IClientsListProps> = ({ screenSize }) => {
  const navigate = useNavigate();
  const [getClients] = useLazyGetClientsQuery();
  const query = (offset: number, search?: string): IQuerySortParams => {
    return {
      sortBy: 'created_at',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      search: search || '',
    };
  };

  const getCardHeight = useCallback(
    (screenSize: number) => {
      switch (screenSize) {
        case screenDevices.Desktop:
          return 140;
        case screenDevices.Tablet:
          return 160;
        case screenDevices.miniTablet:
          return 220;
        case screenDevices.Mobile:
          return 220;
        default:
          return 140;
      }
    },
    [screenDevices, cardHeight],
  );
  const height = getCardHeight(screenSize);

  const createUserButton = useMemo(
    () => (
      <Tooltip title="Создать приложение">
        <Button
          data-id="create-client-button"
          className="text-15-medium"
          variant="custom"
          component="label"
          color="secondary"
          onClick={() => navigate('create')}
          startIcon={<AddToPhotosOutlinedIcon />}
        />
      </Tooltip>
    ),
    [navigate],
  );

  const handleEventClick = (id?: string) => {
    id && navigate(`/applications/${id}`);
  };

  return (
    <Box data-id="clients" className="wrapper">
      <Box className="content">
        <ListItems<TClient, IQuerySortParams, IClientCardProps>
          heightListItem={height}
          padding={cardHeight.Indent}
          query={query}
          getItems={getClients}
          RowElement={ClientCard}
          searchFormChildren={createUserButton}
          rowElementProps={{
            height: height,
            padding: cardHeight.Indent,
            onClick: handleEventClick,
          }}
        />
      </Box>
    </Box>
  );
};

export const ClientsList = connect(mapStateToProps)(ClientsListComponent);
