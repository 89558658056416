import React, { FC } from 'react';
import styles from './IconWrapper.module.css';
import clsx from 'clsx';

interface IIconWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const IconWrapper: FC<IIconWrapperProps> = ({ 
  children,
  className
}) => {
  return <div className={clsx(styles.iconWrapper, className && className)}>{children}</div>;
};
