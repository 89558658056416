import React from 'react';
import { Sentry } from './sentry/Sentry';
import { License } from './license/License';
import { TrustedProviders } from './trustedProviders/TrustedProviders';
import { ProviderTypeConfig } from 'src/helpers';

export const addProvidersTypes: ProviderTypeConfig = {
  ['LDAP']: 'LDAP',
  ['ALDPRO']: 'ALDPRO',
  ['1C']: '1C',
  ['IDM']: 'IDM',
  ['QRCODE']: 'Device flow',
};

export enum addProviderType {
  LDAP = 'LDAP',
  ALDPRO = 'ALDPRO',
  _1C = '1C',
  IDM = 'IDM',
}

export enum addMethodsType {
  QRCODE = 'QRCODE',
  ESIA = 'ESIA',
}

export const AdvancedSettings: React.FC = () => {
  return (
    <>
      <TrustedProviders />
      <License />
      <Sentry />
    </>
  );
};
