import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import React, { FC, ReactElement, useState } from 'react';
import styles from './TopBar.module.css';

type TClasses = Partial<{
  topBar: string;
  topBarButton: string;
  logoIcon: string;
  sectionName: string;
  logoText: string;
  userIconWrapper: string;
  userName: string;
  exitButton: string;
  menuButtonWrapper: string;
  menuButton: string;
  bottom: string;
  helpButtonWrapper: string;
  helpButton: string;
  version: string;
  copyright: string;
}>;

type TItems = {
  title: string;
  onClick: () => void;
  icon: ReactElement;
  allowedRoles: string[];
}[];

type TTopBar = {
  userInfo: {
    avatarUrl?: string;
    username?: string;
    role: string;
    onUserClick?: () => void;
  };
  projectInfo: {
    logoUrl: string;
    projectName: string;
    onExitClick: () => void;
    menuButtonDisabled: boolean;
    items: TItems;
    manualUrl?: string;
    version?: string;
    copyright?: string;
    sectionName: string;
  };
  customStyles?: { [key: string]: string };
  classes?: TClasses;
};

const TopBar: FC<TTopBar> = ({
  projectInfo: {
    logoUrl,
    projectName,
    onExitClick,
    menuButtonDisabled,
    manualUrl,
    version,
    copyright,
    items,
    sectionName,
  },
  userInfo: { username, avatarUrl, role, onUserClick },
  classes,
  customStyles,
}) => {
  const [isOpenNavigation, setIsOpenNavigation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = () => {
    setIsOpenNavigation(!isOpenNavigation);
  };
  const theme = useTheme();

  return (
    <Box id="top-bar" className={clsx(styles['top-bar'], classes?.topBar)}>
      <Button
        disabled={menuButtonDisabled}
        id="open-navigation-button"
        className={clsx(styles['top-bar-button'], classes?.topBarButton)}
        onClick={handleOpenMenu}
      >
        <AppsOutlinedIcon sx={{ color: theme.palette.custom.main }} />
      </Button>
      <img className={clsx(styles['logo-icon'], classes?.logoIcon)} src={logoUrl} />
      <span className={clsx(styles['section-name'], classes?.sectionName)}>{sectionName}</span>
      <span className={clsx(styles['logo-text'], classes?.logoText)}>{projectName}</span>
      <div className={styles.account} onClick={handleClick}>
        {avatarUrl ? (
          <img
            src={avatarUrl}
            className={clsx(styles['user-icon-circle'], classes?.userIconWrapper)}
          />
        ) : (
          <div className={clsx(styles['user-icon-circle'])}>
            <PersonOutlineOutlinedIcon className={styles['user-icon']} />
          </div>
        )}
      </div>
      <AccountsMenu
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={handleClose}
        username={username}
        avatarUrl={avatarUrl}
        onExitClick={onExitClick}
      />
      <NavigationMenu
        classes={classes}
        role={role}
        manualUrl={manualUrl}
        version={version}
        copyright={copyright}
        items={items}
        isOpen={isOpenNavigation}
        onClose={() => setIsOpenNavigation(false)}
      />
    </Box>
  );
};

const NavigationMenu = ({
  isOpen,
  onClose,
  items,
  role,
  classes,
  ...bottomItems
}: {
  items: TItems;
  role: string;
  isOpen: boolean;
  onClose: () => void;
  classes?: TClasses;
  manualUrl?: string;
  version?: string;
  copyright?: string;
}) => {
  const filteredItems = items.filter((item) => item.allowedRoles.includes(role));
  const hasBottomItems = !!Object.values(bottomItems).filter((value) => !!value).length;

  return (
    <Drawer
      className={styles['menu-modal']}
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        sx: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        },
      }}
      classes={{ paper: styles['modal-paper'], docked: styles['menu-modal'] }}
    >
      <div className={styles['menu-modal-header']}>
        <p>Меню</p>
        <Button startIcon={<CloseOutlinedIcon />} onClick={onClose} />
      </div>
      <div
        id="navigation-menu"
        className={clsx(styles.menu, {
          [styles['menu-min-height']]: hasBottomItems,
        })}
      >
        {filteredItems.map((item) => (
          <div
            className={clsx(styles['menu-button-wrapper'], classes?.menuButtonWrapper)}
            onClick={() => {
              onClose();
              item.onClick();
            }}
            key={item.title}
          >
            {item.icon}
            <button className={clsx(styles['menu-button'], classes?.menuButton)}>
              {item.title}
            </button>
          </div>
        ))}
        {hasBottomItems && (
          <div className={clsx(styles.bottom, classes?.bottom)}>
            {bottomItems.manualUrl && (
              <a
                className={clsx(styles['help-button-wrapper'], classes?.helpButtonWrapper)}
                href={bottomItems.manualUrl}
              >
                <InfoOutlinedIcon />
                <button className={clsx(styles['help-button'], classes?.helpButton)}>
                  Справка
                </button>
              </a>
            )}
            {bottomItems.version && (
              <div className={clsx(styles.version, classes?.version)}>
                Версия {bottomItems.version}
              </div>
            )}
            {bottomItems.copyright && (
              <div className={clsx(styles.copyright, classes?.copyright)}>
                {bottomItems.copyright}
              </div>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

const AccountsMenu = ({
  anchorEl,
  isOpen,
  onClose,
  username,
  avatarUrl,
  onExitClick,
}: {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  onExitClick: () => void;
  username?: string;
  avatarUrl?: string;
}) => {
  return (
    <Menu
      id="accounts-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      classes={{ paper: styles['accounts-menu-paper'] }}
    >
      <div className={styles['accounts-menu-header']}>
        <div className={styles['user-image-wrapper']}>
          {avatarUrl ? (
            <img src={avatarUrl} className={clsx(styles['user-image'])} />
          ) : (
            <div className={clsx(styles['user-image-wrapper'])}>
              <PersonOutlineOutlinedIcon className={styles['user-image']} />
            </div>
          )}
        </div>
        <p className={clsx(styles['user-text'])}>{username}</p>
        <Button onClick={onExitClick} className={styles['user-exit']}>
          Выход
        </Button>
      </div>
    </Menu>
  );
};

export default TopBar;
