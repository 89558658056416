import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { CustomMuiIcon } from '../../custom/CustomMuiIcon';
import { CustomTypography } from '../../custom/CustomTypography';
import styles from '../../settings/Settings.module.css';
import { ConfirmationModal } from '../../modal/ConfirmationModal';
import LicenseItem from './LicenseItem';
import { activateLicense, getLicenses, TLicenseInfo } from './requests';
import { useDispatch } from 'react-redux';
import { setNotice } from 'src/redux/noticesSlice';

const LicenseComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [inputLicense, setInputLicense] = useState<string>('');
  const [licenses, setLicenses] = useState<TLicenseInfo[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    loadLicense();
  }, []);

  const loadLicense = async () => {
    const licenseList = await getLicenses();
    setLicenses(licenseList);
  };

  const handleSaveLicense = async () => {
    if (!inputLicense) return;

    try {
      const isActivated = await activateLicense(inputLicense, 'Описание лицензии');
      if (isActivated) {
        loadLicense();
      }
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Ошибка активации лицензии: ${error}`,
          timestamp: new Date().toString(),
          avatar: '',
        })
      );
    }
    setInputLicense('');
    setIsModalOpen(false);
  };

  return (
    <>
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Лицензия
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails
          style={{ alignItems: 'flex-start' }}
          className={styles['accordion-details']}
        >
          <Button
            variant="custom2"
            className={styles['add-button']}
            onClick={() => setIsModalOpen(true)}
          >
            Добавить лицензию
          </Button>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
            {licenses.map((license) => (
              <LicenseItem
                key={license.value}
                licenseName={license.value}
                licenseId={license.id}
                expirationTime={license.expiration_time}
                licenseKeys={license.flags}
                connectedProvidersList={license.providers}
                updateLicenses={loadLicense}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      {isModalOpen && (
        <ConfirmationModal
          title="Введите значение"
          children={
            <TextField
              value={inputLicense}
              onChange={(e) => setInputLicense(e.target.value)}
              fullWidth
              variant="standard"
              style={{ marginBottom: 20 }}
            />
          }
          actionButtonText="Добавить"
          isOpen={isModalOpen}
          onAction={handleSaveLicense}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export const License = memo(LicenseComponent);
