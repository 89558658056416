import React, { useState } from 'react';
import styles from '../../profile/ChangePassword.module.css';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { PasswordTextfield } from '../../custom/PasswordTextfield';
import { validatePassword } from '../../../helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useChangePasswordByOwnerMutation } from '../../../redux/services/owner';
import { useGetSettingsQuery } from '../../../redux/services/settings';
import { CustomTypography } from '../../custom/CustomTypography';

export const ChangePasswordByOwner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<null | string>(null);
  const [changePassword, { isLoading: changePasswordLoading }] = useChangePasswordByOwnerMutation();
  const { data: dataSettings } = useGetSettingsQuery();
  const { userId } = useParams();

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите новый пароль');
    const validationError = validatePassword(newPassword, dataSettings);
    if (validationError) {
      return setNewPasswordError(validationError);
    }
  };

  const handleChangePassword = async () => {
    if (!newPasswordError && newPassword && userId) {
      await changePassword({ newPassword, userId });
      navigate(location.pathname.replace('/change-password', ''));
    }
  };
  return (
    <div className={'wrapper'}>
      <div className={'content'}>
        <div className={styles['change-password']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('text-24-medium', 'font-golos')}
          >
            Изменить пароль
          </CustomTypography>
          <CustomTypography
            color="secondary"
            style={{ marginBottom: 24 }}
            className={clsx('text-14')}
          >
            После смены пароля произойдет выход из аккаунта на всех устройствах, где вы вошли с
            текущим паролем.
          </CustomTypography>
          {userId && (
            <>
              <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
                Новый пароль
              </CustomTypography>
              <PasswordTextfield
                value={newPassword}
                onChange={(e) => {
                  if (newPasswordError) setNewPasswordError(null);
                  setNewPassword(e.target.value);
                }}
                onBlur={getNewPasswordError}
                className={clsx('custom', styles['password-textfield'])}
                FormHelperTextProps={{
                  className: clsx('text-14'),
                }}
                error={!!newPasswordError}
                helperText={newPasswordError ? newPasswordError : ''}
                fullWidth
                variant="standard"
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
                <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
                  Отмена
                </Button>
                <Button
                  disabled={changePasswordLoading}
                  onClick={handleChangePassword}
                  variant="custom"
                  style={{ marginLeft: 24 }}
                >
                  Изменить
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
