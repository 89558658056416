import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import React, { ChangeEvent, Dispatch, FC, MutableRefObject } from 'react';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CustomMuiIcon } from 'src/components/custom/CustomMuiIcon';
import { CustomTypography } from '../../custom/CustomTypography';
import { TClientSettingsInputs } from './ClientSettings';
import styles from './ClientSettings.module.css';
import { WidgetColor } from './WidgetColor';

type TEditApplicationFooter = {
  avatarSrc: string | null;
  coverSrc: string | null;
  setSaveModalOpen: Dispatch<boolean>;
  applicationFormChanged: boolean;
  savedCallback: MutableRefObject<(() => void) | undefined>;
};

export const EditApplicationFooter: FC<TEditApplicationFooter> = ({
  avatarSrc,
  setSaveModalOpen,
  applicationFormChanged,
  savedCallback,
  coverSrc,
}) => {
  const methods = useFormContext<TClientSettingsInputs>();
  const { register, control, setValue } = methods;

  const showAvatar = useWatch({ control, name: 'show_avatar_in_widget' });
  const hideHeader = useWatch({ control, name: 'hide_widget_header' });
  const hideFooter = useWatch({ control, name: 'hide_widget_footer' });

  const navigate = useNavigate();
  return (
    <>
      <Accordion className={clsx(styles.panel, styles.accordion)}>
        <AccordionSummary
          className={styles['accorion-summary']}
          classes={{ content: styles['accorion-summary-content'] }}
          expandIcon={
            <IconButton size="large" disableRipple={false}>
              <CustomMuiIcon Icon={KeyboardArrowDownOutlinedIcon} />
            </IconButton>
          }
        >
          <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
            Внешний вид виджета
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails className={styles['accordion-details']}>
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>
              Показывать логотип приложения на виджете
            </CustomTypography>
            <Switch
              checked={showAvatar}
              {...register('show_avatar_in_widget', {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  setValue('show_avatar_in_widget', event.target.checked, { shouldDirty: true });
                },
              })}
            />
          </div>
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>Скрыть шапку</CustomTypography>
            <Switch
              checked={hideHeader}
              {...register('hide_widget_header', {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  setValue('hide_widget_header', event.target.checked, { shouldDirty: true });
                },
              })}
            />
          </div>
          <div className={styles['switch-wrapper']}>
            <CustomTypography className={clsx('text-14')}>Скрыть подвал</CustomTypography>
            <Switch
              checked={hideFooter}
              {...register('hide_widget_footer', {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  setValue('hide_widget_footer', event.target.checked, { shouldDirty: true });
                },
              })}
            />
          </div>
          <FormProvider {...methods}>
            <WidgetColor
              coverSrc={coverSrc}
              name={useWatch({ control, name: 'name' })}
              avatarSrc={avatarSrc}
              showAvatar={showAvatar}
              hideFooter={hideFooter}
              hideHeader={hideHeader}
            />{' '}
          </FormProvider>
        </AccordionDetails>
      </Accordion>

      <div className={styles['submit-buttons']}>
        <Button
          onClick={() => {
            if (applicationFormChanged) {
              savedCallback.current = () => navigate(-1);
              return setSaveModalOpen(true);
            }
            navigate(-1);
          }}
          variant="custom"
          color="secondary"
        >
          Отмена
        </Button>
        <Button
          className={styles['create-button']}
          type="submit"
          variant="custom"
          disabled={!applicationFormChanged}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};
